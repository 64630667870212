import { lazy } from 'react'
import { HasToBeNotAuthenticated } from '@dataplace.ai/features/guards'
import { RouteType } from '@dataplace.ai/types'
import DummyGuard from '@dataplace.ai/features/guards/DummyGuard'
import HasToBeNotAuthenticatedInvitation from '@dataplace.ai/features/guards/HasToBeNotAuthenticatedInvitation'

// Auth components
const SignIn = lazy(() => import('./features/SignIn/components/pages/SignInPage'))
const SignUp = lazy(() => import('./features/SignUp/SignUp'))
const SignOut = lazy(() => import('./features/SignOut/SignOut'))
const ForgotPassword = lazy(() => import('./features/ForgotPassword/ForgotPassword'))
const ResetPassword = lazy(() => import('./features/ResetPassword/ResetPassword'))
// Auth routes
const authRoutes: RouteType = {
  id: 'AuthRoot',
  rootPath: '/',
  redirect: '/sign-in',
  guard: HasToBeNotAuthenticated,
  children: [
    {
      childPath: '/sign-in',
      name: 'Sign In',
      component: SignIn,
    },
    {
      childPath: '/sign-up',
      name: 'Sign Up',
      component: SignUp,
    },
    {
      childPath: '/sign-up/invitation/:invitationId',
      name: 'Invitation Sign Up',
      component: SignUp,
      guard: HasToBeNotAuthenticatedInvitation,
    },
    {
      childPath: '/sign-out',
      name: 'Sign Out',
      component: SignOut,
      guard: DummyGuard,
    },
    {
      childPath: '/forgot-password',
      name: 'Forgot Password',
      component: ForgotPassword,
    },
    {
      childPath: '/reset-password',
      name: 'Reset Password',
      component: ResetPassword,
    },
  ],
}

export const routes = [authRoutes]
