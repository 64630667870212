/* eslint-disable prefer-destructuring */
/* eslint-disable max-lines */
import { createSlice } from '@reduxjs/toolkit'
import { ILocation, IRange } from '@dataplace.ai/types'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { config } from 'apps/placeme/src/config'
import { createFlashMessage, createNavbarNotification } from '@dataplace.ai/functions/utils'
import { getI18n } from 'react-i18next'
import { AxiosResponse } from 'axios'
import { IAnalysisState } from './@types/IAnalysisState'
import type { AppThunk } from '../../../redux/store'
import { ICombinedSectionTile, ISectionTile } from './@types/ISectionTile'
import { ITile } from './@types/ITile'
import { ITileData } from './@types/ITileData'
import { INote } from './@types/INote'
import { ENDPOINTS } from '../../../constants/endpoints'
import { ITemporaryCatchmentData } from './@types/ITemporaryCatchmentData'
import { tilesData } from '../utils/tilesData'
import { IFreqRanges } from './@types/IFreqRanges'
import { ICatchmentData } from './@types/ICatchmentData'
import { IAnalysisResponse, IReportCatchment, IReportTileData } from './@types/savedReportTypes'
import { IRemoteConfigTileErrors, IRemoteConfigTilesWithPlans } from './@types/IRemoteConfigTiles'
import { fetchSubscriptionInfo } from '../../ChooseLocationReport/chooseLocationSlice'
import { ITemplatesData, IUserTemplatesResponseData } from '../components/organisms/AnalyseTemplates/templatesData'

const { v4: uuidv4 } = require('uuid')

const initialState: IAnalysisState = {
  analysisDbInfo: null,
  values: [],
  canBeSave: true,
  ranges: {
    value: [],
    loading: true,
    error: '',
  },
  showHints: true,
  layers: {},
  currentPlan: 'bronze',
  tiles: [],
  plans: [],
  pdfState: {
    loading: false,
    error: null,
    value: null,
  },
  savingState: {
    loading: false,
    error: null,
    value: null,
  },
  comparisonState:[],
  synchronousTilesLoading: false,
  isEdit: false,
  demoModalId: '',
  templateState: [],
  templateTilesLoading: false,
  isMaxTilesNumberExceeded: false,
}

const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    toggleHints: (state) => {
      state.showHints = !state.showHints
    },
    saveTiles: (state, action) => {
      state.values = action.payload
    },
    saveRanges: (state, action) => {
      state.ranges = action.payload
    },
    saveCreditsAmount: (state, action) => {
      state.creditsAmount = action.payload
    },
    saveTemporaryCatchment: (state, action) => {
      state.layers.temporaryCatchment = action.payload
    },
    toggleCanBeSave: (state, action) => {
      state.canBeSave = action.payload
    },
    saveAnalysisDbInfo: (state, action) => {
      state.analysisDbInfo = action.payload
    },
    resetAnalysisState: (state) => ({
      ...initialState,
      tiles: [...state.tiles],
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      plans: [...state.plans],
    }),
    saveTilesFromRemoteConfig: (state, action) => {
      state.tiles = action.payload
    },
    savePlansFromRemoteConfig: (state, action) => {
      state.plans = action.payload
    },
    toggleSubscriptionType: (state, action) => {
      state.currentPlan = action.payload
    },
    savePdfState: (state, action) => {
      state.pdfState = action.payload
    },
    saveSavingState: (state, action) => {
      state.savingState = action.payload
    },
    saveComparedLocation: (state, action) => {
      state.comparedLocation = action.payload
    },
    saveComparisonState: (state, action) => {
      state.comparisonState = action.payload
    },
    saveSortedTiles: (state, action) => {
      state.sortedTiles = action.payload
    },
    saveSynchronousTilesLoading: (state, action) => {
      state.synchronousTilesLoading = action.payload
    },
    toggleIsEdit: (state, action) => {
      state.isEdit = action.payload
    },
    setDemoModalId: (state, action) => {
      state.demoModalId = action.payload
    },
    saveTemplateState: (state, action) => {
      state.templateState = action.payload
    },
    saveTemplateTilesLoading: (state, action) => {
      state.templateTilesLoading = action.payload
    },
    saveUsersTemplates: (state, action) => {
      state.usersTemplates = action.payload
    },
    saveIsMaxTilesNumberExceeded: (state, action) => {
      state.isMaxTilesNumberExceeded = action.payload
    },
  },
})

export const { reducer } = analysisSlice
export const {
  saveTiles, saveRanges, saveCreditsAmount, saveTemporaryCatchment, toggleHints, toggleCanBeSave, saveAnalysisDbInfo,
  resetAnalysisState, saveTilesFromRemoteConfig, savePlansFromRemoteConfig, toggleSubscriptionType,
  savePdfState, saveSavingState, saveComparedLocation, saveComparisonState, saveSortedTiles,
  saveSynchronousTilesLoading, toggleIsEdit, setDemoModalId, saveTemplateState, saveTemplateTilesLoading,
  saveUsersTemplates, saveIsMaxTilesNumberExceeded,
} = analysisSlice.actions

// Tile actions

export const addTileAction = (
  token: string,
  section: ISectionTile,
  tile: ITile,
  isExtraPaid?: boolean,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const { analysis } = getState()
  const categoryAlreadyExist = analysis.values.find((dataTile) => dataTile.id === section.id)
  const idNumber = (id: string) => {
    if (id.split('-').length > 1) {
      return tile.id
    }
    return `${tile.id}-${uuidv4()}`
  }

  if (categoryAlreadyExist) {
    dispatch(
      saveTiles(
        analysis.values.map((dataTile) =>
          (dataTile.id === section.id
            ? {
              ...dataTile,
              tiles: [...dataTile.tiles, {
                ...tile,
                id: idNumber(tile.id),
                isExtraPaid,
              }],
            }
            : dataTile)),
      ),
    )
  } else {
    dispatch(saveTiles([...analysis.values, {
      ...section,
      tiles: [{
        ...tile,
        id: idNumber(tile.id),
        isExtraPaid,
      }],
    }]))
  }
}

export const deleteTileAction = (
  token:string,
  categoryId: string,
  tileId: string,
  analyseId: string,
): AppThunk => async (dispatch, getState): Promise<void> => {
  // in this action getState is used more then one time - it's because we always need "fresh" state in case
  // user add some tile while the response is pending
  const { analysis } = getState()
  const tileToDelete = analysis?.values?.find(cat => cat?.id === categoryId)?.tiles?.find(tile => tile?.id === tileId)

  if (tileToDelete?.data?.value) {
    const catchmentId = tileToDelete?.chosenRange?.catchmentId
    const body = {
      data:{
        catchments_list:[catchmentId],
      },
    }
    let response
    try {
      response = await getAxios(config.API_URL, token).delete(`project/${analyseId}/tiles` || '', body)
    }
    catch (e) {
      createFlashMessage({
        message: 'status.error.delete_analyse',
      })
    }
    // eslint-disable-next-line no-empty
    finally {
      if (response) {
        createFlashMessage({
          message: 'status.success.delete_analyse',
        })
        const { analysis } = getState()
        const updatedList = analysis.values.map((dataTile) =>
          (dataTile.id === categoryId
            ? {
              ...dataTile,
              tiles: dataTile.tiles.filter((t) => t.id !== tileId),
            }
            : dataTile))
        dispatch(
          saveTiles(updatedList.filter((dataTile) => !!dataTile.tiles.length)),
        )
      }
    }
  }
  else {
    const { analysis } = getState()
    const updatedList = analysis.values.map((dataTile) =>
      (dataTile.id === categoryId
        ? {
          ...dataTile,
          tiles: dataTile.tiles.filter((t) => t.id !== tileId),
        }
        : dataTile))
    dispatch(
      saveTiles(updatedList.filter((dataTile) => !!dataTile.tiles.length)),
    )
  }
}

export const fetchTileDataAction = (
  token: string,
  categoryId: string,
  tileId: string,
  subscriptionId: string | null,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const catchmentId = getState().analysis.values?.find(
    c => c.id === categoryId,
  )?.tiles?.find(t => t.id === tileId)?.chosenRange?.catchmentId
  const getTileType = (id: string) => id.split('-')[0]
  if (catchmentId) {
    const body = {
      catchment_id: catchmentId,
    }
    let data: ITileData

    const endpoint = Object.entries(ENDPOINTS).find((key) => key[0] === `${getTileType(tileId).toUpperCase()}_TILE`)?.[1]

    try {
      const response = await getAxios(config.API_URL, token).post(endpoint || '', body)
      data = {
        loading: false,
        error: '',
        value: response.data,
      }
      if (response.status === 204) {
        window?.localStorage.setItem('noDataModal', catchmentId || 'no catchment')
        window?.dispatchEvent(new CustomEvent('noDataModal'))
      }
    } catch (e) {
      data = {
        loading: false,
        error: e.error,
        value: null,
      }
    }
    if (data?.value) {
      const updatedList = getState().analysis.values.map((analysisCategory) =>
        (analysisCategory.id === categoryId
          ? {
            ...analysisCategory,
            tiles: analysisCategory.tiles.map((tile) =>
              (tile.id === tileId
                ? {
                  ...tile,
                  id: tile?.chosenRange?.catchmentId ? `${tileId?.split('-')[0]}-${tile?.chosenRange?.catchmentId}` : tile.id,
                  data,
                }
                : tile)),
          }
          : analysisCategory))

      dispatch(saveTiles(updatedList))

      // update tokens number, set timeout needed because of adding usage record after returning response in marmur
      setTimeout(() => dispatch(fetchSubscriptionInfo(token, subscriptionId)), 2000)
    }
  }
}

export const fetchComparedTileDataAction = (
  token: string,
  categoryId: string,
  tileId: string,
  subscriptionId: string | null,
  mainCatchmentId : string,
  comparedCatchmentId: string,
  doublePropertiesTilesExtendedBody?: Record<string, unknown>,
  formData?: FormData,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const endpointId = tileId.split('-')[0]
  if (mainCatchmentId && comparedCatchmentId && endpointId) {
    const body = {
      endpoint: endpointId,
      mainCatchmentId,
      comparedCatchmentId,
      additionalParameters: doublePropertiesTilesExtendedBody,
    }
    let data: ITileData

    const endpoint = ENDPOINTS.COMPARED_LOCATION_DATA

    if (formData) {
      formData.append('compared_catchment_id', comparedCatchmentId)
      formData.append('endpoint', endpointId)
      formData.append('additional_parameters', JSON.stringify(doublePropertiesTilesExtendedBody))
    }

    try {
      const response = await getAxios(config.API_URL, token).post(endpoint || '', formData || body)
      data = {
        loading: false,
        error: '',
        value: response.data,
      }
    } catch (e) {
      data = {
        loading: false,
        error: e.error,
        value: null,
      }

      dispatch(saveComparisonState(getState().analysis?.comparisonState?.map(item => {
        if (item?.tile === tileId) {
          return {
            loading: false,
            error:'placeme.compared_location.label.error_data',
            tile: item?.tile,
          }
        }

        return item
      })))
    }
    if (data?.value) {
      const updatedList = getState().analysis.values.map((analysisCategory) =>
        (analysisCategory.id === categoryId
          ? {
            ...analysisCategory,
            tiles: analysisCategory.tiles.map((tile) =>
              (tile.id === tileId
                ? {
                  ...tile,
                  data,
                }
                : tile)),
          }
          : analysisCategory))

      dispatch(saveTiles(updatedList))
      dispatch(saveComparisonState(getState().analysis?.comparisonState?.map(item => {
        if (item?.tile === tileId) {
          return {
            loading: false,
            error:'',
            tile: item?.tile,
          }
        }

        return item
      })))

      // update tokens number, set timeout needed because of adding usage record after returning response in marmur
      setTimeout(() => dispatch(fetchSubscriptionInfo(token, subscriptionId)), 2000)
    } else {
      dispatch(saveComparisonState(getState().analysis?.comparisonState?.map(item => {
        if (item?.tile === tileId) {
          return {
            loading: false,
            error:'placeme.compared_location.label.error_data',
            tile: item?.tile,
          }
        }

        return item
      })))
    }
  }
}

interface IParams {
  length: number,
  lat: number,
  lng: number,
  mode: string,
}

export const fetchTemporaryCatchment = (
  token: string,
  params: IParams,
): AppThunk => async (dispatch, _getState): Promise<void> => {
  const body = {
    length: params.length,
    lat: params.lat,
    lng: params.lng,
    mode: params.mode,
  }
  let data: ITemporaryCatchmentData

  const endpoint = ENDPOINTS.FETCH_CATCHMENT_PREVIEW

  try {
    const response = await getAxios(config.API_URL, token).post(endpoint, body)
    data = {
      loading: false,
      error: '',
      value: response.data,
    }
  } catch (e) {
    data = {
      loading: false,
      error: e.error,
      value: null,
    }
  }

  if (data?.value)
  { const newLayer = {
    id: uuidv4(),
    layer: {
      data: {
        coordinates: data?.value?.geojson?.coordinates,
        type: data?.value?.geojson?.type,
        properties: {},
      },
      options: {
        type: 'geojson',
        id: uuidv4(),
        style: {
          color: '#0000a2',
          fillColor: '#0000a2',
          weight: 0,
          fillOpacity: 0.3,
        },
      },
    },
  }
  dispatch(saveTemporaryCatchment(newLayer)) }
}

export const saveTileData = (
  categoryId: string,
  tileId: string,
  data: (ITileData | undefined),
): AppThunk => async (dispatch, getState): Promise<void> => {
  const { values } = getState().analysis
  const updatedList = values.map((analysisCategory) =>

    (analysisCategory.id === categoryId
      ? {
        ...analysisCategory,
        tiles: analysisCategory.tiles.map((tile) =>
          (tile.id === tileId
            ? {
              ...tile,
              id:  tile?.chosenRange?.catchmentId ? `${tileId?.split('-')[0]}-${tile?.chosenRange?.catchmentId}` : tile.id,
              data,
            }
            : tile)),
      }
      : analysisCategory))
  dispatch(saveTiles(updatedList))
}

export const openAnalyse = (
  token: string,
  id: string,
  comparedAnalyseId?: string,
): AppThunk => async (dispatch): Promise<IAnalysisResponse> => {
  let data: IAnalysisResponse
  const endpoint = ENDPOINTS.CREATE_DELETE_SHARE_ANALYSE
  const mainDataEndpoint = `${endpoint}/${id}`
  const withComparedDataEndpoint = `${endpoint}/compare/${comparedAnalyseId}`
  try {
    const response = await getAxios(config.API_URL, token).get(comparedAnalyseId
      ? withComparedDataEndpoint
      : mainDataEndpoint)
    data = {
      loading: false,
      error: '',
      value: response.data,
    }
  } catch (e) {
    data = {
      loading: false,
      error: e.error,
      value: null,
    }
  }
  const saveEachAnalyse = () => {
    const catchments = data.value?.catchments || []
    if (catchments) {
      const tiles = [] as (ICatchmentData & IReportTileData)[]

      Object.entries(catchments).forEach(([_catchmentId, catchment]:[string, IReportCatchment]) => {
        const catchmentData = catchment?.catchmentData || {}
        const comparedCatchmentData = catchment?.comparedCatchment || {}
        const catchmentTiles: IReportTileData[] = catchment?.tiles || []

        catchmentTiles.forEach((tile) => {
          tiles.push({
            ...tile,
            ...catchmentData,
            compared: comparedCatchmentData,
          })
        })
      })
      const sortedTiles = tiles.sort((a, b) => a.creationTime.localeCompare(b.creationTime))
      dispatch(saveSortedTiles(sortedTiles))
    }
  }
  if (data?.value) {
    dispatch(toggleCanBeSave(false))
    const {
      // eslint-disable-next-line no-unused-vars
      catchments, ...dbData
    } = data.value
    dispatch(saveAnalysisDbInfo(dbData))
    const comparedFromLocal = window?.localStorage?.getItem('comparedLocation')
    if (data?.value?.comparedReport) {
      dispatch(saveComparedLocation({
        alreadyGenerated: comparedFromLocal ? JSON.parse(comparedFromLocal || '')?.alreadyGenerated : false,
        generatedFromNow: comparedFromLocal ? JSON.parse(comparedFromLocal || '')?.generatedFromNow : false,
        location: data?.value?.comparedReport,
      }))
    } else {
      dispatch(saveComparedLocation({
        alreadyGenerated: comparedFromLocal ? JSON.parse(comparedFromLocal || '')?.alreadyGenerated : false,
        generatedFromNow: comparedFromLocal ? JSON.parse(comparedFromLocal || '')?.generatedFromNow : false,
        location: comparedFromLocal ? JSON.parse(comparedFromLocal || '')?.location : false,
      }))
    }
  } else {
    dispatch(saveAnalysisDbInfo(undefined))
  }
  saveEachAnalyse()

  return data
}

export const toggleHintsAction = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(toggleHints())
}

export const addTileWithData = (
  token: string, tile: ICatchmentData & IReportTileData,
): AppThunk => async (dispatch): Promise<void> =>
{
  const tileTypeId = tile?.categoryId || ''
  const categoryMetadata = tilesData.find(cat => cat.tiles.some(tileMetadata => tileMetadata.id === tileTypeId))

  const tileId = `${tileTypeId}-${tile?.catchmentId || ''}`
  const tileMetadata = categoryMetadata?.tiles.find(t => t.id === tileTypeId) as ITile

  const catchmentType = tile?.type || ''
  const tileRange = {
    id: `${catchmentType}-${tile.length}`,
    value: tile?.length || 0,
    type: catchmentType,
    catchmentId: tile.catchmentId || '',
    geoJSON: tile.geojson,
  }
  let comparedTileRange
  if (tile?.compared) {
    const comparedCatchmentType = tile?.compared?.type || ''
    comparedTileRange = {
      id: `${comparedCatchmentType}-${tile.compared?.length}`,
      value: tile?.compared?.length || 0,
      type: comparedCatchmentType,
      catchmentId: tile.compared?.catchmentId || '',
      geoJSON: tile.compared?.geojson,
    }
  }
  const tileData: ITileData = {
    loading: false,
    error: '',
    value: tile.value,
  }
  if (categoryMetadata && tileMetadata) {
    dispatch(addTileAction(token, categoryMetadata, {
      ...tileMetadata,
      id: tileId,
    }))
    dispatch(saveChosenRangeAction(categoryMetadata.id, tileId, tileRange as IRange, comparedTileRange as IRange))
    dispatch(saveTileData(categoryMetadata.id, tileId, tileData))
  }
}

export const saveTilesFromRemoteConfigAction = (remoteTilesWithPlans: IRemoteConfigTilesWithPlans | null): AppThunk =>
  (dispatch): void => {
    if (remoteTilesWithPlans) {
      // this combines remoteConfig tile data with hardcoded tilesData object
      const combinedTilesData = [] as unknown[]
      remoteTilesWithPlans.categories?.forEach(remoteCategory => {
        const localCategory = tilesData.find(localCategory => localCategory?.id === remoteCategory?.id)
        if (localCategory) {
          const { tiles } = localCategory
          const combinedTiles = [] as unknown[]

          // adding tile to sidebar manually - find category and push tile

          // if (remoteCategory.id === 'surroundings') {
          //   remoteCategory.tiles.push({
          //     id: 'employees_in_range',
          //     countries: ['PL'],
          //     plan: 'white',
          //   })
          // }

          remoteCategory.tiles.forEach(tile => {
            const localTile = tiles.find(localTile => localTile?.id === tile?.id)
            if (localTile) {
              const plan = tile.plan === null ? remoteTilesWithPlans.plans.find(plan => plan?.id === 'white') : remoteTilesWithPlans.plans.find(plan => plan?.id === tile.plan)
              if (plan) {
                combinedTiles.push({
                  ...localTile,
                  ...tile,
                  plan,
                })
              }
            }
          })
          combinedTilesData.push({
            ...localCategory,
            ...remoteCategory,
            tiles: combinedTiles,
          })
        }
      })

      dispatch(saveTilesFromRemoteConfig(combinedTilesData))
      // for country check issue
      window?.localStorage?.setItem('onlyTiles', JSON.stringify(combinedTilesData))
      dispatch(savePlansFromRemoteConfig(remoteTilesWithPlans?.plans))
    }
  }

export const saveTileErrors = (remoteTilesErrors: IRemoteConfigTileErrors[] | null): AppThunk =>
  (): void => {
    if (remoteTilesErrors?.length) {
      const i18n = getI18n()
      const messageForAll = remoteTilesErrors?.find(error => error.id === 'message_for_all')
      const filteredTileErrors = remoteTilesErrors?.filter(item => item?.id !== 'message_for_all' && item?.visible)
      if (filteredTileErrors?.length) {
        createNavbarNotification({
          id: 'tile_errors',
          text: `${`${i18n.t('placeme.tile_errors_1')} ${filteredTileErrors?.map((error, index) =>
          { const dotOrColon = index < (filteredTileErrors?.length - 1) ? '' : '.'
            return ` ${i18n.t(`placeme.tile.${error?.id}`)}${dotOrColon}`
          })} ${i18n.t('placeme.tile_errors_2')}`}`,
          showCloseButton: true,
          showContactButton: false,
          translate: false,
          error: true,
        })
      }

      if (messageForAll && messageForAll?.visible) {
        createNavbarNotification({
          id: 'error_message_for_all',
          text: `${i18n.t(`placeme.error.${messageForAll?.id}`)}`,
          showCloseButton: true,
          showContactButton: false,
          translate: false,
          error: true,
        })
      }
    }
  }

export const fetchTilesFromRemoteConfigAction = (token: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await getAxios(config.API_URL, token).get(ENDPOINTS.TILES)
    if (response) {
      const newTiles = {
        ...response?.data,
      }
      const tileErrors = [...response?.data?.tileErrors] as IRemoteConfigTileErrors[]

      if (newTiles) {
        const newTilesStringified = JSON.stringify(newTiles)
        const localStorageTilesData = window?.localStorage?.getItem('placemeTiles')
        if (localStorageTilesData !== newTilesStringified) {
          dispatch(saveTilesFromRemoteConfigAction(newTiles))
          window?.localStorage?.setItem('placemeTiles', newTilesStringified)
        }
      }
      if (tileErrors) {
        const newTileErrors = JSON.stringify(tileErrors)
        dispatch(saveTileErrors(tileErrors))
        window?.localStorage?.setItem('tileErrors', newTileErrors)
      }
    }
  }

// Note actions

export const saveNoteAction = (
  categoryId: string,
  tileId: string,
  note: INote,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const { analysis } = getState()
  const updatedList = analysis.values.map((dataTile) =>
    (dataTile.id === categoryId
      ? {
        ...dataTile,
        tiles: dataTile.tiles.map((t) => (t.id === tileId
          ? {
            ...t,
            notes: t.notes?.length ? [...t?.notes?.filter(n => n.id !== note.id), note] : [note],
          }
          : t)),
      }
      : dataTile))
  dispatch(saveTiles(updatedList))
}

export const deleteNoteAction = (
  categoryId: string,
  tileId: string,
  note: INote,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const { analysis } = getState()
  const updatedList = analysis.values.map((dataTile) =>
    (dataTile.id === categoryId
      ? {
        ...dataTile,
        tiles: dataTile.tiles.map((t) =>
          (t.id === tileId
            ? {
              ...t,
              notes: t.notes?.filter((n) => n.id !== note.id),
            }
            : t)),
      }
      : dataTile))
  dispatch(saveTiles(updatedList))
}

export const saveNotesToDBAction = (token: string, notes: unknown[]): AppThunk => async (): Promise<void> => {
  if (notes) {
    try {
      const notesData = {
        notes,
      }
      await getAxios(config.API_URL, token).post<INote[]>(ENDPOINTS.CRUD_NOTE, notesData)
    }
    catch (e) {
      createFlashMessage({
        message: 'status.error.note.creation_failed',
      })
    }
  }
}

export const deleteNoteFromDBAction = (token: string, note: INote): AppThunk => async (): Promise<void> => {
  try {
    const response = await getAxios(config.API_URL, token).delete<INote>(`${ENDPOINTS.CRUD_NOTE}/${note.id}`)
    createFlashMessage({
      message: response.statusText,
    })
  }
  catch (e) {
    createFlashMessage({
      message: 'status.error.note.deletion_failed',
    })
  }
}

export const updateNoteInDBAction = (token: string, note: INote): AppThunk => async (): Promise<void> => {
  try {
    const noteBody = {
      id: note.id,
      text: note.text,
    }
    await getAxios(config.API_URL, token).patch<INote>(ENDPOINTS.CRUD_NOTE, noteBody)
  } catch (e) {
    createFlashMessage({
      message: 'status.error.note.update_failed',
    })
  }
}

export const fetchNotesAction = (token: string, reportId: string, categoryId: string, catchmentId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const response = await getAxios(config.API_URL, token).get(`${ENDPOINTS.CRUD_NOTE}?catchment_id=${catchmentId}&report_id=${reportId}`)
      const { data: notes } = response
      dispatch(saveFetchedNotesAction(categoryId, catchmentId, notes))
    } catch (e) {
      createFlashMessage({
        message: 'status.error.note.get_failed',
      })
    }
  }

const saveFetchedNotesAction = (categoryId: string, catchmentId: string, notes: INote[]): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { analysis } = getState()
    const updatedList = analysis.values.map((dataTile) =>
      (dataTile.id === categoryId
        ? {
          ...dataTile,
          tiles: dataTile.tiles.map((t) => (t.chosenRange?.catchmentId === catchmentId
            ? {
              ...t,
              notes,
            }
            : t)),
        }
        : dataTile))
    dispatch(saveTiles(updatedList))
  }

// Range actions

export const saveChosenRangeAction = (
  categoryId: string,
  tileId: string,
  range: IRange | undefined,
  comparedRange?: IRange | undefined,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const { analysis } = getState()
  const updatedList = analysis.values.map((dataTile) =>
    (dataTile.id === categoryId
      ? {
        ...dataTile,
        tiles: dataTile.tiles.map((t) =>
          (t.id === tileId
            ? {
              ...t,
              chosenRange: range,
              comparedChosenRange: comparedRange,
            }
            : t)),
      }
      : dataTile))
  dispatch(saveTiles(updatedList))
}

export const addNewRangeAction = (range: IRange): AppThunk => async (dispatch, getState): Promise<void> => {
  const { ranges } = getState().analysis
  const updatedList = (): IRange[] => {
    const isAlreadyAdded = !!ranges.value.find((r) => ((r.type === range.type) && (r.value === range.value)))
    if (!isAlreadyAdded) {
      const newArray = [range, ...ranges.value]
      return newArray.length > 8 ? newArray.slice(0, 8) : newArray
    }
    return ranges.value
  }
  dispatch(saveRanges({
    ...ranges,
    value: updatedList(),
  }))
}

export const saveNewRangeAction = (
  token: string,
  userId: string,
  categoryId: string,
  tileId: string,
  range: IRange,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const {
    location, analysis,
  } = getState()

  const body = range.type === 'custom'
    ? {
      reportId: location?.analyseId,
      lat: location?.value?.lat,
      lng: location?.value?.lng,
      geojson: {
        type: range.geoJSON?.type,
        coordinates: range.geoJSON?.coordinates,
      },
      mode: range.type,
    }
    : {
      reportId: location?.analyseId,
      lat: location?.value?.lat,
      lng: location?.value?.lng,
      length: range.value,
      mode: range.type,
    }

  try {
    const newRange = await getAxios(config.API_URL, token).post<IRange>(ENDPOINTS.FETCH_CATCHMENT, body)
    dispatch(saveChosenRangeAction(categoryId, tileId, {
      id: `${range.type}-${range.value}`,
      value: range.value,
      type: range.type,
      catchmentId: newRange.data.id,
      geoJSON: newRange.data.geojson,
    }))

    const notes = analysis?.values?.find(category =>
      category.id === categoryId)?.tiles?.find(tile => tile.id === tileId)?.notes

    if (notes) {
      const notesData = notes.map(note => ({
        id: note?.id,
        text: note?.text,
        tileType: tileId?.split('-')[0],
        catchmentId: newRange?.data?.id,
        reportId: location?.analyseId,
      }))
      dispatch(saveNotesToDBAction(token, notesData))
    }

    // useful when we'll do the logic with saving tiles data to the same catchment
    // dispatch(fetchRangesAction(token, userId))
  } catch (e) {
    createFlashMessage({
      message: 'status.error.catchment.creation_failed',
    })
  }
}

export const fetchRangesAction = (
  token: string,
  userId: string,
): AppThunk => async (dispatch, getState): Promise<void> => {
  // TODO co gdy będzie dobry zasięg do ponownego wykorzystania ale stary?
  // useful when we'll do the logic with saving tiles data to the same catchment
  // dispatch(saveRanges(
  //   {
  //     value: [],
  //     loading: true,
  //     error: ''
  //   }
  // ))

  let data

  try {
    const response = await getAxios(config.API_URL, token).get<IFreqRanges[]>(
      `/user/${userId}/catchments?workspace_id=${window?.localStorage?.workspaceId}&filter=ranges&prefer_report=${getState().location.analyseId}`,
    )
    data = {
      loading: false,
      error: '',
      // filter out range line below 100 [for big_ben case 50m line]
      value: response.data.filter(item => !(item?.type === 'line' && item?.length < 100))?.map(data => ({
        id: `${data?.type}-${data?.length}-${uuidv4()}`,
        value: data?.length,
        type: data?.type,
        catchmentId: data?.id,
        geoJSON: data?.geoJson,
      })),
    }
  } catch (e) {
    data = {
      loading: false,
      error: e.error,
      value: [{
        id: 'walk-10',
        value: 10,
        type: 'walk',
      }],
    }
  } finally {
    dispatch(saveRanges(data))
  }
}
export const fetchWorkspaceUsageValue = (token: string): AppThunk => async (dispatch, _getState): Promise<void> => {
  let saveData
  try {
    const response = await getAxios(config.API_URL, token).get(`${ENDPOINTS.WORKSPACE}/${window?.localStorage?.workspaceId}`)
    saveData = {
      error: '',
      value: response.data,
    }
  } catch (e) {
    saveData = {
      error: e.error,
      value: null,
    }
  }
  dispatch(saveCreditsAmount(saveData?.value?.creditsTotal))
}

// Result actions

export const deleteResultAction = (token: string, reportId: string,
  catchmentId: string, categoryType: string, tileCategory: string, locationId?: string, tileId?: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const response = await getAxios(config.API_URL, token)
        .delete(`${ENDPOINTS.DELETE_RESULT.replace('<report_id>', reportId)}?catchment_id=${catchmentId}&category_type=${categoryType}${locationId && `&location_id=${locationId}`}`)
      if (locationId && tileId) {
        const saveData = {
          loading: false,
          error: '',
          value: response.data,
        }

        dispatch(saveTileData(tileCategory, tileId, saveData))
      }
    } catch (e) {
      createFlashMessage({
        message: 'status.error.result.deletion_failed',
      })
    }
  }

export const createPDFAction = (token: string, analyseId: string, language: string,
  tiles: {catchmentId: string}[], name: string, comparedLocationId?: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const body = comparedLocationId
      ? {
        reportId: analyseId,
        language,
        tiles,
        name,
        compareReports: comparedLocationId,
      }
      : {
        reportId: analyseId,
        language,
        tiles,
        name,
      }

    let saveData
    try {
      const response = await getAxios(config.API_URL, token).post<IRange>(ENDPOINTS.PDF, body)

      saveData = {
        loading: false,
        error: null,
        value: response.data,
      }
    }
    catch (e) {
      saveData = {
        loading: false,
        error:  'status.info.generic.something_went_wrong',
        value: null,
      }
    }
    finally {
      dispatch(savePdfState(saveData))
    }
  }

export const saveAnalyse = (
  token: string,
  analyseName: string,
  projectType: string,
  projectName?: string,
  projectId?: string, // folder id
  analyseId?: string,
  reports?: (string[] | null),
): AppThunk =>
  async (dispatch): Promise<void> => {
    let body
    if (reports) { reports.push(analyseId || '') }

    if (projectType || analyseName) {
      const status = projectType.toLowerCase().includes('private')
        ? 'private'
        : 'view'

      body = {
        status,
        name: analyseName,
      }
      try {
        await getAxios(config.API_URL, token).patch(
          `${ENDPOINTS.CREATE_DELETE_SHARE_ANALYSE}/${analyseId}`,
          body,
        )
      }
      // eslint-disable-next-line no-empty
      finally {}
    }

    if (projectId) {
      body = {
        reports,
      }
      let saveData
      try {
        const response = await getAxios(config.API_URL, token).patch(
          `${ENDPOINTS.CREATE_ADD_DELETE_FOLDER}/${projectId}`,
          body,
        )
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
      } catch (e) {
        saveData = {
          loading: false,
          error: e.error,
          value: null,
        }
      }
      finally {
        dispatch(saveSavingState(saveData))
      }
    }

    if (projectName) {
      body = {
        workspaceId: window?.localStorage?.workspaceId,
        folderName: projectName,
      }
      let saveData
      try {
        const response = await getAxios(config.API_URL, token).post(
          `${ENDPOINTS.CREATE_ADD_DELETE_FOLDER}`,
          body,
        )
        saveData = {
          loading: false,
          error: null,
          value: response.data,
        }
        try {
          body = {
            reports: [analyseId],
          }
          const response = await getAxios(config.API_URL, token).patch(
            `${ENDPOINTS.CREATE_ADD_DELETE_FOLDER}/${saveData.value.id}`,
            body,
          )
          saveData = {
            loading: false,
            error: null,
            value: response.data,
          }
        } catch (e) {
          saveData = {
            loading: false,
            error: 'status.info.generic.something_went_wrong',
            value: null,
          }
        }
      } catch (e) {
        saveData = {
          loading: false,
          error:  'status.info.generic.something_went_wrong',
          value: null,
        }
      }
      finally {
        dispatch(saveSavingState(saveData))
      }
    }
  }

export const compareLocationCatchmentAndDataAction = (
  token: string, mainCatchment: string, range: IRange, categoryId: string, tileId: string,
  subscriptionId: string, comparedLocation: ILocation, doublePropertiesTilesExtendedBody?: Record<string, unknown>,
  formData?: FormData,
): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const {
      location, analysis,
    } = getState()

    const body = {
      mainCatchment,
      reportId: location?.comparedAnalyseId,
      lat: comparedLocation?.lat,
      lng: comparedLocation?.lng,
      length: range.value,
      mode: range.type,
    }
    let saveData : {value?: IRange} = {
      value: undefined,
    }
    try {
      const response = await getAxios(config.API_URL, token).post<IRange>(ENDPOINTS.FETCH_CATCHMENT, body)
      saveData = {
        value: response.data,
      }
      // save compared location catchment
      const updatedList = analysis?.values?.map(category => (category?.id === categoryId
        ? {
          ...category,
          tiles: category?.tiles?.map(tile => (tile?.id === tileId
            ? {
              ...tile,
              comparedChosenRange: {
                id: `${range.type}-${range.value}`,
                value: range.value,
                type: range.type,
                catchmentId: saveData?.value?.id,
                geoJSON: saveData?.value?.geojson,
              },
            }
            : tile)),
        }
        : category))

      dispatch(saveTiles(updatedList))
    }
    catch (e) {
      saveData = {
        value: undefined,
      }
      dispatch(saveComparisonState(getState().analysis?.comparisonState?.map(item => {
        if (item?.tile === tileId) {
          return {
            loading: false,
            error:'placeme.compared_location.label.error_catchment',
            tile: item?.tile,
          }
        }

        return item
      })))
    }
    finally {
      // ok catchment -> fetching data for compared location
      if (saveData?.value) {
        try {
          await dispatch(fetchComparedTileDataAction(
            token,
            categoryId,
            tileId,
            subscriptionId,
            mainCatchment,
            saveData?.value?.id,
            doublePropertiesTilesExtendedBody,
            formData,
          ))
        }
        catch (e) {
          // error fetch compared location data
        }
        finally {
          // ok fetch data for compared location
        }
      }
    }
  }

export const catchmentAndDataAction = (
  token: string, range: IRange, categoryId: string, tileId: string,
  subscriptionId: string,
): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { location } = getState()

    const body = range.type === 'custom'
      ? {
        reportId: location?.analyseId,
        lat: location?.value?.lat,
        lng: location?.value?.lng,
        geojson: {
          type: range.geoJSON?.type,
          coordinates: range.geoJSON?.coordinates,
        },
        mode: range.type,
      }
      : {
        reportId: location?.analyseId,
        lat: location?.value?.lat,
        lng: location?.value?.lng,
        length: range.value,
        mode: range.type,
      }
    let newRange: AxiosResponse<IRange>
    try {
      newRange = await getAxios(config.API_URL, token).post<IRange>(ENDPOINTS.FETCH_CATCHMENT, body)

      if (newRange?.data?.id) {
        const body = {
          catchment_id: newRange.data.id,
        }
        let data: ITileData

        const endpoint = Object.entries(ENDPOINTS).find((key) => key[0] === `${tileId.split('-')?.[0].toUpperCase()}_TILE`)?.[1]

        try {
          const response = await getAxios(config.API_URL, token).post(endpoint || '', body)
          data = {
            loading: false,
            error: '',
            value: response.data,
          }
          if (response.status === 204) {
            window?.localStorage.setItem('noDataModal', newRange.data.id || 'no catchment')
            window?.dispatchEvent(new CustomEvent('noDataModal'))
          } else {
            // full success
            const { analysis } = getState()
            const sectionTile = analysis?.tiles?.find(cat => cat.id === categoryId) as ICombinedSectionTile
            const tile = sectionTile?.tiles?.find(t => t.id === tileId.split('-')?.[0]) as ITile
            const categoryAlreadyExist = analysis.values.find((cat) => cat.id === categoryId)

            if (categoryAlreadyExist) {
              dispatch(
                saveTiles(
                  analysis.values.map((cat) =>
                    (cat.id === categoryId
                      ? {
                        ...cat,
                        tiles: [...cat.tiles, {
                          ...tile,
                          id: `${tileId.split('-')?.[0]}-${newRange.data.id}`,
                          chosenRange: {
                            id: `${range.type}-${range.value}`,
                            value: range.value,
                            type: range.type,
                            catchmentId: newRange.data.id,
                            geoJSON: newRange.data.geojson,
                          },
                          data,
                        }],
                      }
                      : cat)),
                ),
              )
            } else {
              dispatch(saveTiles([...analysis.values, {
                ...sectionTile,
                tiles: [{
                  ...tile,
                  id: `${tileId.split('-')?.[0]}-${newRange.data.id}`,
                  chosenRange: {
                    id: `${range.type}-${range.value}`,
                    value: range.value,
                    type: range.type,
                    catchmentId: newRange.data.id,
                    geoJSON: newRange.data.geojson,
                  },
                  data,
                }],
              }]))
            }
            dispatch(saveTemplateState(analysis?.templateState?.map(item => (item?.tile === tileId
              ? {
                ...item,
                loading: false,
                error: '',
              }
              : item))))

            setTimeout(() => dispatch(fetchSubscriptionInfo(token, subscriptionId)), 2000)
          }
        } catch (e) {
          data = {
            loading: false,
            error: e.error,
            value: null,
          }
          // add tile to analyze (user can try again by manually setting range)
          const { analysis } = getState()
          const sectionTile = analysis?.tiles?.find(cat => cat.id === categoryId) as ICombinedSectionTile
          const tile = sectionTile?.tiles?.find(t => t.id === tileId.split('-')?.[0]) as ITile
          dispatch(addTileAction(token, sectionTile, tile))
          dispatch(saveTemplateState(analysis?.templateState?.map(item => (item?.tile === tileId
            ? {
              ...item,
              loading: false,
              error: 'placeme.templates.data_error',
            }
            : item))))
        }
      }
    }
    catch (e) {
      const { analysis } = getState()
      // error catchment save
      dispatch(saveTemplateState(analysis?.templateState?.map(item => (item?.tile === tileId
        ? {
          ...item,
          loading: false,
          error: 'placeme.templates.catchment_error',
        }
        : item))))
      // add tile to analyze (user can try again by manually setting range)
      const sectionTile = analysis?.tiles?.find(cat => cat.id === categoryId) as ICombinedSectionTile
      const tile = sectionTile?.tiles?.find(t => t.id === tileId.split('-')?.[0]) as ITile
      dispatch(addTileAction(token, sectionTile, tile))
    }
  }

export const fetchUsersTemplatesAction = (
  token:string,
) :AppThunk => async (dispatch): Promise<void> => {
  let response : {data: IUserTemplatesResponseData[]}
  const endpoint = ENDPOINTS.TEMPLATES
  try {
    response = await getAxios(config.API_URL, token).get(endpoint || '', {})
    if (response?.data) {
      const usersTemplates: ITemplatesData[] = response?.data?.map(item => ({
        ...item,
        tiles: item.tiles?.map(tile => ({
          id: tile?.id,
          suggestedRange: tile?.range
            ? {
              value: tile?.range?.length,
              type: tile?.range?.mode,
            }
            : undefined,
        })),
      }))
      dispatch(saveUsersTemplates(usersTemplates))
    }
  }
  catch (e) {
    createFlashMessage({
      message: 'status.error.saved_templates.get_failed',
    })
  }
}

export const saveTemplateAction = (
  token:string, title: string, tiles: {id: string, range?: {
    length: number | null,
    mode: IRange['type'] | null,
  }}[],
) :AppThunk => async (): Promise<void> => {
  const body = {
    title,
    tiles,
  }
  const endpoint = ENDPOINTS.TEMPLATES
  try {
    const response = await getAxios(config.API_URL, token).post(endpoint || '', body)
    if (response) {
      createFlashMessage({
        message: 'status.success.saved_templates.post_success',
      })
    }
  }
  catch (e) {
    createFlashMessage({
      message: 'status.error.saved_templates.post_failed',
    })
  }
}

export const deleteTemplateAction = (
  token:string, templateId: string,
) :AppThunk => async (): Promise<void> => {
  const endpoint = ENDPOINTS.TEMPLATES
  try {
    const response = await getAxios(config.API_URL, token).delete(`${endpoint}/${templateId}`)
    if (response) {
      createFlashMessage({
        message: 'status.success.delete_templates.delete_success',
      })
    }
  }
  catch (e) {
    createFlashMessage({
      message: 'status.error.delete_templates.delete_failed',
    })
  }
}

export default analysisSlice.reducer

