/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { IChildrenProps } from '@dataplace.ai/types'
import { firebaseService } from '@dataplace.ai/services'
import { checkSessionRequest, createSessionRequest } from '@dataplace.ai/functions/requests/authRequests'
import { createFlashMessage, redirectToSignOut } from '@dataplace.ai/functions/utils'
import { handleTokenExpire } from '@dataplace.ai/functions/session'

export interface IUser {
  user: firebase.User | null,
  userLoaded: boolean
}
export interface IUserContext {
  userData: IUser,
  clearUser: (() => void),
  reloadUser: (() => Promise<void>) | (() => unknown),
  createSession: ((user: firebase.User | null) => Promise<IUser | null>) | (() => unknown),
}

export const AuthContext = React.createContext<IUserContext>({
  userData: {
    user: null,
    userLoaded: false,
  },
  reloadUser: () => {},
  clearUser: () => {},
  createSession: () => {},
})

export const AuthProvider = ({ children }: IChildrenProps):JSX.Element => {
  const [userData, setUser] = useState<IUser>({
    user: null,
    userLoaded: false,
  })

  useEffect(() => {
    // nie musimy sprawdzać sesji, jeśli user i tak się chce wylogować;
    // prowadziłoby to do dziwnego zapętlenia przekierowań
    if (window.location.pathname !== '/sign-out') {
      // set session lifetime - firebase pozwala na ustawienie długości życia sesji.
      // Odkąd i tak sami nią zarządzamy za pomocą endpointów, opcja ta nie jest nam do niczego potrzebna - stąd NONE
      firebaseService.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
        .then(async () => {
          // wywołujemy funkcję sprawdzającą sesję; ona też jest okomentowana
          await fetchUserData()
        })
    } else {
      setUser({
        user: null,
        userLoaded: true,
      })
    }
  }, [])

  const fetchUserData = async () => {
    /* Ta funkcja działą tak, że odpytuje endpoint weryfikujący, czy session cookie jest ok. Jeśli jest ok, wyciągnij
     z niego user id i utwórz na tej podstawie custom auth token i zwróć. Ten custom token jest użyty, by zalogować
     usera metodą firebasową i zdobyć legitny obiekt user, który ma wszystkie potrzebne metody itd. i jest spójny
     z userem, który przychodzi po wszystkich innych akcjach (sign up, sign in) */
    checkSessionRequest()
      .then(response => {
        // response data is empty string (if there's no session) or custom token
        if (response.data) {
          firebase.auth().signInWithCustomToken(response.data)
            .then(async (userCredential) => {
              // Signed in
              setUser({
                user: userCredential.user,
                userLoaded: true,
              })
            })
            .catch((_error) => {
              createFlashMessage({
                message: 'auth.error.refreshing_session',
              })
              handleTokenExpire()
              setUser({
                user: null,
                userLoaded: true,
              })
              // redirect to sign in if user is not there UPDATE: guards should take care of it
              // if (!window.location.href.includes('auth')) redirectToSignIn()
            }) }
        else {
          handleTokenExpire()
          setUser({
            user: null,
            userLoaded: true,
          })
        }
      })
      .catch(error => {
        if (error?.response?.data) {
          // eslint-disable-next-line no-alert
          // alert(error.response.data)
          handleTokenExpire()
          setUser({
            user: null,
            userLoaded: true,
          })

          // redirect to sign in if user is not there UPDATE: guards should take care of it
          // if (!window.location.href.includes('auth')) redirectToSignIn()
        }
      })
  }

  const createSession = async (user: firebase.User | null) => new Promise<IUser | null>(resolve => {
    if (!user) { return }

    user.getIdToken(true)
      .then(idToken => {
        createSessionRequest(idToken)
          .then(async _response => {
            const newUserData = {
              user,
              userLoaded: true,
            }
            setUser(newUserData)
            resolve(newUserData)
          })
          .catch(error => {
            createFlashMessage({
              message: error?.response?.data || 'generic.error.bad_request',
            })
            redirectToSignOut()
            resolve(null)
          })
      })
  })

  const reloadUser = async ():Promise<IUser | null> => {
    if (typeof userData.user === 'object') {
      const clonedUser = Object.assign(Object.create(Object.getPrototypeOf(userData.user)), userData.user)
      await clonedUser?.reload()
      // cloning class instance is the only elegant way to force user state to update
      return await createSession(clonedUser) as IUser
    }
    return null
  }

  const clearUser = () => {
    setUser({
      user: null,
      userLoaded: true,
    })
  }

  return (
    <AuthContext.Provider
      value={{
        userData,
        createSession,
        clearUser,
        reloadUser: () => reloadUser(),
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
