import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { TextButton, CoinsButton, ButtonProduct } from '@dataplace.ai/ui-components/atoms'
import { IMaxRanges, IRange } from '@dataplace.ai/types'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { handleMaxRanges } from 'apps/placeme/src/functions/handleMaxRanges'
import { checkComparedCoinsValue } from 'apps/placeme/src/functions'
import { RootState } from '../../../../../redux/store'
import { RangeTile } from '../../organisms'
import {
  deleteTileAction,
  fetchRangesAction,
  saveNewRangeAction,
  saveTemporaryCatchment,
} from '../../../slice/analysisSlice'
import { ChosenRangeExistPopup } from '../ChosenRangeExistPopup'
import { tilesWithDoublePropertiesChoose } from '../Tile/constant/TilesWithDoublePropertiesChoose'
import { customRangesList } from '../../organisms/Tiles/BigBen/clientRangesList'
import { tilesWithoutComparedLocation } from '../AddAnotherLocationModal/constants'

const RangeSelectionWrapper = styled.div(() => css`
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  padding: 1.25rem;
`)

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`

export type RangeSelectionProps = {
  category: string,
  tile: string,
  userId: string,
  maxRanges?:IMaxRanges,
  isExtraPaid?: boolean
  clientRanges?: boolean
}

export const RangeSelection : React.FC<RangeSelectionProps> = ({
  category, tile, maxRanges, userId, isExtraPaid, clientRanges,
}) => {
  // variables
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const authContext = useContext(AuthContext)
  const { analytics } = useContext(AnalyticsContext)

  useEffect(() => {
    authContext.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  const {
    ranges, values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const {
    currentSubscriptionData, analyseId, comparedAnalyseId,
  } = useSelector((state: RootState) => state.location)

  const scope = clientRanges
    ? handleMaxRanges(customRangesList[tile?.split('-')[0]].value, maxRanges, (comparedLocation?.generatedFromNow || comparedLocation?.alreadyGenerated))
    : handleMaxRanges(ranges?.value, maxRanges, (
      comparedLocation?.generatedFromNow
      || comparedLocation?.alreadyGenerated))

  // states
  const [chosenRange, setChosenRange] = useState<IRange>()
  const [isWarningModalOpen, toggleWarningModalOpen] = useState(false)
  const [token, setToken] = useState('')
  const [isCatchmentRequested, setIsCatchmentRequested] = useState(false)

  // functions
  const handleChosenRangeChange = (range?: IRange, preview?: boolean) => {
    setChosenRange(preview ? range : (scope.find(x => x?.type === range?.type && x?.value === range?.value && range?.type !== 'custom') || range))
  }

  const checksIfRangeIsAlreadyChosen = (value: number, type: string) => {
    let isRangeAlreadyChosen = false
    const tilesOfType = values?.find(cat => cat.id === category)?.tiles?.filter(t => t.id.split('-')[0] === tile.split('-')[0])
    if (tilesOfType?.length) {
      for (const t of tilesOfType) {
        if (t?.chosenRange?.value === value && t?.chosenRange?.type === type && t?.chosenRange?.type !== 'custom') {
          isRangeAlreadyChosen = true
          break
        }
      }
    }
    return isRangeAlreadyChosen
  }

  const handleSave = () => {
    if (chosenRange && !checksIfRangeIsAlreadyChosen(chosenRange.value, chosenRange.type) && token?.length) {
      analytics?.track('Tile Data Generated', {
        tile: tile?.split('-')[0],
        range: {
          type: chosenRange?.type,
          length: chosenRange?.value,
        },
      })
      setIsCatchmentRequested(true)
      dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', category, tile, chosenRange))
      dispatch(saveTemporaryCatchment(undefined))
    } else if (chosenRange) {
      toggleWarningModalOpen(true)
    }
  }

  const handleDeleteTile = () => {
    analytics?.track('Tile Cancel Button Clicked', {
      tile: tile?.split('-')[0],
      range: {
        type: chosenRange?.type,
        value: chosenRange?.value,
      },
    })
    dispatch(deleteTileAction(token, category, tile, comparedAnalyseId || analyseId || ''))
  }

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (token?.length && !ranges.value.length) dispatch(fetchRangesAction(token, userId))
  }, [token])

  useEffect(() => {
    if (values.length) {
      if (!chosenRange) {
        const rangeFromStore = values?.find(c => c?.id === category)?.tiles
          ?.find(t => t.id === tile)?.chosenRange
        setChosenRange(rangeFromStore || scope?.[0])

        if (rangeFromStore) {
          const isRangeFromStoreIncludedInLastUsed = !!scope.find(item =>
            item?.type === rangeFromStore?.type && item?.value === rangeFromStore?.value)
          if (!isRangeFromStoreIncludedInLastUsed) scope.push(rangeFromStore)
        }
      } else {
        setChosenRange(chosenRange)
      }
    }
  }, [JSON.stringify(values), ranges?.value])

  return scope?.length && !isCatchmentRequested
    ? (
      <>
        <ChosenRangeExistPopup
          close={() => toggleWarningModalOpen(false)}
          isOpen={isWarningModalOpen}
        />
        <RangeSelectionWrapper>
          {!!scope?.length && (
            <RangeTile
              chosenRange={chosenRange || scope?.[0]}
              handleChosenRangeChange={handleChosenRangeChange}
              maxRanges={maxRanges}
              ranges={scope}
              tile={tile}
            />
          )}
          <ButtonsWrapper>
            <TextButton onClick={handleDeleteTile}>
              <p>{t('generic.cancel')}</p>
            </TextButton>
            {tilesWithDoublePropertiesChoose.includes(tile.split('-')[0])
              ? (
                <ButtonProduct
                  disabled={!chosenRange}
                  margin='0 0 0 1.82rem'
                  onClick={handleSave}
                  value={1}
                >
                  {t('generic.apply')}
                </ButtonProduct>
              )
              : (
                <CoinsButton
                  disabled={!chosenRange}
                  isExtraPaid={isExtraPaid}
                  isUnlimited={
                    currentSubscriptionData?.value?.planExact.includes('unlimited')
                    || currentSubscriptionData?.value?.plan === 'white'
                    || clientRanges
                  }
                  margin='0 0 0 1.82rem'
                  onClick={handleSave}
                  text={isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
                  tileId={tile ?? ''}
                  value={checkComparedCoinsValue(
                    comparedLocation?.generatedFromNow,
                    !!comparedLocation?.location,
                    !tilesWithoutComparedLocation.includes(tile?.split('-')[0]),
                  )}
                />
              )}
          </ButtonsWrapper>
        </RangeSelectionWrapper>
      </>
    )
    : (<Loader />) }

