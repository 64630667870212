import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '@dataplace.ai/features'
import {
  redirectToInvitation,
  redirectToSignUp,
} from '@dataplace.ai/functions/utils'
import { IChildrenProps } from '@dataplace.ai/types'
import { useLocation } from 'react-router-dom'

export const HasToBeNotAuthenticated = ({ children }: IChildrenProps): JSX.Element => {
  const authContext = useContext(AuthContext)
  const location = useLocation()

  const [didRedirectTrigger, setRedirectTriggerFlag] = useState(false)

  const fetchIdFromUrl = () => {
    const location = window.location.pathname.split('/')
    let id = ''
    for (const [index, elem] of location.entries()) {
      if (elem === 'invitation') {
        id = location?.[index + 1]
        break
      }
      id = ''
    }
    return id
  }

  const urlInvitationId = fetchIdFromUrl()

  useEffect(() => {
    if (parseInt(process.env.NX_AUTH_ENABLED || '0', 10) !== 0 && authContext.userData.userLoaded && !didRedirectTrigger) {
      setRedirectTriggerFlag(true) // prevents repeating redirect
      if (authContext.userData?.user?.email) {
        if (!authContext.userData?.user?.emailVerified) { // means that user is authenticated but not verified
          if (!window.location.href.includes('sign-up')) redirectToSignUp() // redirect to code verify if user is not there
        } else {
          // eslint-disable-next-line no-lonely-if
          redirectToInvitation(urlInvitationId) // redirect to invitation component if authenticated (for further check)
        }
      }
    }
  }, [authContext.userData.userLoaded, location.pathname])

  return (
    <>
      {children}
    </>
  )
}

export default HasToBeNotAuthenticated
