/* eslint-disable max-lines,import/no-cycle */
import React from 'react'
import { ReactComponent as PopulationIcon } from '../../../../../../libs/shared/assets/src/lib/icons/placeme/population.svg'
import { ReactComponent as MarketIcon } from '../../../../../../libs/shared/assets/src/lib/icons/placeme/market.svg'
import { ReactComponent as TransportIcon } from '../../../../../../libs/shared/assets/src/lib/icons/placeme/transport.svg'
import { ReactComponent as PotentialIcon } from '../../../../../../libs/shared/assets/src/lib/icons/placeme/potential.svg'
import { ReactComponent as SurroundingsIcon } from '../../../../../../libs/shared/assets/src/lib/icons/placeme/surroundings.svg'
import { ReactComponent as TrafficIcon } from '../../../../../../libs/shared/assets/src/lib/icons/placeme/traffic.svg'
import { ReactComponent as VisualizationIcon } from '../../../../../../libs/shared/assets/src/lib/icons/placeme/visualization.svg'
import { ReactComponent as BigBenIcon } from '../../../../../../libs/shared/assets/src/lib/icons/placeme/duzyben.svg'
import { ISectionTile } from '../slice/@types/ISectionTile'
import { PeopleTile } from '../components/organisms/Tiles/Population/People'
import { DemographyTile } from '../components/organisms/Tiles/Population/Demography'
import { SpendingTile } from '../components/organisms/Tiles/Population/Spending'
import { MunicipalityPopulationTile } from '../components/organisms/Tiles/Population/MunicipalityPopulation'
import { PurchasingPowerTile } from '../components/organisms/Tiles/Population/PurchasingPower'
import { ParkingTile } from '../components/organisms/Tiles/AccessLogistics/Parking'
import { AccessRangeTile } from '../components/organisms/Tiles/AccessLogistics/AccessRange'
import { TransportTile } from '../components/organisms/Tiles/AccessLogistics/Transport'
import { CannibalizationTile } from '../components/organisms/Tiles/Market/Cannibalization'
import { GravityModelTile } from '../components/organisms/Tiles/Potential/GravityModel'
import { InvestmentsTile } from '../components/organisms/Tiles/Surroundings/Investments'
import { LocalMarketShareTile } from '../components/organisms/Tiles/Potential/LocalMarketShare'
import { PoisTile } from '../components/organisms/Tiles/Market/Pois'
import { BuildingsTile } from '../components/organisms/Tiles/Surroundings/Buildings'
import { VisitingHoursTile } from '../components/organisms/Tiles/Traffic/VisitingHours'
import { TrafficVisualisationTile } from '../components/organisms/Tiles/Traffic/TrafficVisualisation'
import { CustomerOriginTile } from '../components/organisms/Tiles/Traffic/CustomerOrigin'
import { LandUsePlanTile } from '../components/organisms/Tiles/Visualization/LandUsePlan'
import { CitiesPopulationTile } from '../components/organisms/Tiles/Visualization/CitiesPopulation'
import { TrafficVisualisationMapTile } from '../components/organisms/Tiles/Visualization/TrafficVisualisationMap'
import { TrafficDataSet, InhabitantsDataSet, SpendingDataSet,
  PoisDataSet, PurchasingPowerDataSet, PopulationHeatmapDataSet, MunicipalityPopulationDataSet } from './dataSetUpdates'
import { PopulationAndSpendingTile } from '../components/organisms/Tiles/BigBen/PopulationAndSpending'
import { TrafficTile } from '../components/organisms/Tiles/BigBen/Traffic'
import { CompetitionAndSurroundingsTile } from '../components/organisms/Tiles/BigBen/CompetitionAndSurroundings'
import { SimilarLocationTile } from '../components/organisms/Tiles/BigBen/SimilarLocation'
import { PotentialTile } from '../components/organisms/Tiles/BigBen/Potential'
import { SalesPotentialTile } from '../components/organisms/Tiles/Potential/SalesPotential'
import { PopulationHeatmapTile } from '../components/organisms/Tiles/Visualization/PopulationHeatmap'
import { SatelliteTile } from '../components/organisms/Tiles/Visualization/Satellite'
import { EmployeesInRangeTile } from '../components/organisms/Tiles/Surroundings/EmployeesInRange'

const theMostUsedMaxRanges = {
  car: 10,
  bike: 30,
  walk: 30,
  line: 4000,
  custom: 150000000,
}
const defaultMaxRanges = {
  car: 30,
  walk: 30,
  bike: 30,
  line: 5000,
  custom:  517000000,
}
export const tilesData: ISectionTile[] = [
  {
    id: 'population',
    label: 'placeme.category.population',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'people',
        label: 'placeme.tile.people',
        labelForeign: 'placeme.tile.people.foreign',
        labelHint: 'placeme.label_hint.people',
        component: PeopleTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges:  {
          car: 10,
          walk: 25,
          bike: 15,
          line: 3000,
          custom:  260000000,
        },
        helpers: [
          'placeme.people_tile.helper_1',
          'placeme.people_tile.helper_2',
          'placeme.people_tile.helper_3',
        ],
        hints: [
          {
            content: 'placeme.people_tile.hint_1_content',
            example: 'placeme.people_tile.hint_1_example',
          },
          {
            content: 'placeme.people_tile.hint_2_content',
            example: 'placeme.people_tile.hint_2_example',
          },
          {
            content: 'placeme.people_tile.hint_3_content',
            example: 'placeme.people_tile.hint_3_example',
          },
          {
            content: 'placeme.people_tile.hint_4_content',
            example: 'placeme.people_tile.hint_4_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'population',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_people.png?alt=media&token=58807179-6e3a-45f1-a4b5-00aac6573077',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_people_en.png?alt=media&token=104b1af0-7c70-4651-be71-bbdb4da639aa',
        },
      },
      {
        id: 'demography',
        label: 'placeme.tile.demography',
        labelHint: 'placeme.label_hint.demography',
        component: DemographyTile,
        canChooseRange: true,
        maxRanges:  {
          car: 10,
          walk: 30,
          bike: 15,
          line: 3000,
          custom:  260000000,
        },
        helpers: ['placeme.demography_tile.helper_1'],
        isExtraPaid: false,
        hints: [
          {
            content: 'placeme.demography_tile.hint_1_content',
            example: 'placeme.demography_tile.hint_1_example',
          },
          {
            content: 'placeme.demography_tile.hint_2_content',
            example: 'placeme.demography_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'population',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_demography.png?alt=media&token=0030529a-6368-4dbe-9971-f76cfead9a28',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_demography_en.png?alt=media&token=37a0a6be-8146-4077-8aa1-894b80476715',
        },
      },
      {
        id: 'spending',
        label: 'placeme.tile.expanses',
        labelHint: 'placeme.label_hint.expanses',
        component: SpendingTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges:  {
          car: 10,
          walk: 30,
          bike: 15,
          line: 3000,
          custom:  260000000,
        },
        helpers: ['placeme.spending_tile.helper_1'],
        hints: [
          {
            content: 'placeme.spending_tile.hint_1_content',
            example: 'placeme.spending_tile.hint_1_example',
          },
          {
            content: 'placeme.spending_tile.hint_2_content',
            example: 'placeme.spending_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          SpendingDataSet,
        ],
        section: 'population',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_spending.png?alt=media&token=e9afb6ae-3922-4d6e-a40d-100e448c4a0d',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_spending_en.png?alt=media&token=aaeb2cd7-971f-4b0b-8ee2-5ec06cc9653b',
        },
      },
      {
        id: 'purchasing_power',
        label: 'placeme.tile.purchasing_power',
        labelHint: 'placeme.label_hint.purchasing_power',
        component: PurchasingPowerTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges:  {
          car: 10,
          walk: 30,
          bike: 15,
          line: 3000,
          custom:  260000000,
        },
        helpers: ['placeme.purchasing_power_tile.helper_1'],
        hints: [
          {
            content: 'placeme.purchasing_power_tile.hint_1_content',
            example: 'placeme.purchasing_power_tile.hint_1_example',
          },
          {
            content: 'placeme.purchasing_power_tile.hint_2_content',
            example: 'placeme.purchasing_power_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          PurchasingPowerDataSet,
        ],
        section: 'population',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_purchasing_power.png?alt=media&token=45b9e646-e5cc-41ba-ab2c-0c2a8af9159e',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_purchasing_power_en.png?alt=media&token=350be15c-d41c-4f47-873d-ef45a0cb7cc4',
        },
      },
      {
        id: 'municipality_population',
        label: 'placeme.tile.municipality_population',
        labelHint: 'placeme.label_hint.municipality_population',
        component: MunicipalityPopulationTile,
        canChooseRange: false,
        isExtraPaid: false,
        haveToAccept: true,
        acceptDescription: 'placeme.municipality_population.accept_description',
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.municipality_population_tile.helper_1'],
        hints: [
          {
            content: 'placeme.municipality_population_tile.hint_1_content',
            example: 'placeme.municipality_population_tile.hint_1_example',
          },
        ],
        dataSetUpdates: [
          MunicipalityPopulationDataSet,
        ],
        section: 'population',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_municipality_population.png?alt=media&token=90b94516-581f-4eca-ac41-e476aa48bec2',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_municipality_population_en.png?alt=media&token=6c0ff52c-b8bd-4085-bdd1-b9f803ec737f',
        },
      },
    ],
    icon:
  <PopulationIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'market',
    label: 'placeme.category.market',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'pois',
        component: PoisTile,
        canChooseRange: true,
        isExtraPaid: false,
        helpers: ['placeme.pois_tile.helper_1'],
        label: 'placeme.tile.pois_tile',
        labelHint: 'placeme.label_hint.pois',
        maxRanges: {
          car: 10,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: 150000000,
        },
        hints: [
          {
            content: 'placeme.pois_tile.hint_1_content',
            example: 'placeme.pois_tile.hint_1_example',
          },
          {
            content: 'placeme.pois_tile.hint_2_content',
            example: 'placeme.pois_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          PoisDataSet,
        ],
        section: 'market',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_pois.png?alt=media&token=0717bb77-936d-41ba-85f4-4935f9a8d7f1',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_pois_en.png?alt=media&token=4664f8b0-b578-4cb3-aeed-fcceec99e827',
        },
      },
      {
        id: 'cannibalization',
        component: CannibalizationTile,
        canChooseRange: false,
        isExtraPaid: false,
        chosenRange: {
          id: 'line250',
          type: 'line',
          value: 250,
        },
        helpers: ['placeme.cannibalization_tile.helper_1'],
        label: 'placeme.tile.cannibalization',
        labelHint: 'placeme.label_hint.cannibalization',
        hints: [
          {
            content: 'placeme.cannibalization_tile.hint_1_content',
            example: 'placeme.cannibalization_tile.hint_1_example',
          },
          {
            content: 'placeme.cannibalization_tile.hint_2_content',
            example: 'placeme.cannibalization_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'market',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_cannibalization.png?alt=media&token=8e50549e-96ec-4092-858e-88d3af8fea88',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_cannibalization_en.png?alt=media&token=6c0e3b63-3d45-4719-98a8-0d8f1b5f78d1',
        },
      },
    ],
    icon:
  <MarketIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'access_logistics',
    label: 'placeme.category.access_logistics',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'transport',
        label: 'placeme.tile.transport',
        labelHint: 'placeme.label_hint.transport',
        component: TransportTile,
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.transport_tile.helper_1'],
        hints: [
          {
            content: 'placeme.transport_tile.hint_1_content',
            example: 'placeme.transport_tile.hint_1_example',
          },
          {
            content: 'placeme.transport_tile.hint_2_content',
            example: 'placeme.transport_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'access_logistics',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_transport.png?alt=media&token=e7ad88b5-5d3b-49e1-a35f-eb871f1d94c8',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_transport_en.png?alt=media&token=59cf1cad-7aab-4f65-b94b-2f59cb067f19',
        },
      },
      {
        id: 'access_range',
        label: 'placeme.tile.access_range',
        labelHint: 'placeme.label_hint.access_range',
        component: AccessRangeTile,
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.access_range_tile.helper_1'],
        hints: [
          {
            content: 'placeme.access_range_tile.hint_1_content',
            example: 'placeme.access_range_tile.hint_1_example',
          },
          {
            content: 'placeme.access_range_tile.hint_2_content',
            example: 'placeme.access_range_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'access_logistics',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_access_range.png?alt=media&token=37bc01d6-03ab-4c55-b26d-8ee3bb1c7cfd',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_access_range_en.png?alt=media&token=c944b715-8218-487e-b024-8ba950785b48',
        },
      },
      {
        id: 'parking',
        label: 'placeme.tile.parking',
        labelHint: 'placeme.label_hint.parking',
        component: ParkingTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.parking_tile.helper_1'],
        hints: [
          {
            content: 'placeme.parking_tile.hint_1_content',
            example: 'placeme.parking_tile.hint_1_example',
          },
          {
            content: 'placeme.parking_tile.hint_2_content',
            example: 'placeme.parking_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'access_logistics',
        demoImages: {
          pl: '',
          en: '',
        },
      },
    ],
    icon:
  <TransportIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'potential',
    label: 'placeme.category.potential',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'potential_model',
        component: SalesPotentialTile,
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.potential_model_tile.helper_1'],
        label: 'placeme.tile.potential_model',
        labelHint: 'placeme.label_hint.sales_potential',
        hints: [
          {
            content: 'placeme.sales_potential_tile.hint_1_content',
            example: 'placeme.sales_potential_tile.hint_1_example',
          },
          {
            content: 'placeme.sales_potential_tile.hint_2_content',
            example: 'placeme.sales_potential_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'potential',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_potential_model.png?alt=media&token=9bb37fe5-4d0a-4065-b45f-918fe1373bc2',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_potential_model_en.png?alt=media&token=bf048442-ecf4-4df5-80f2-2668bf5c3ca5',
        },
      },
      {
        id: 'gravity_model',
        component: GravityModelTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.gravity_model_tile.helper_1'],
        label: 'placeme.tile.gravity_model',
        labelHint: 'placeme.label_hint.gravity_model',
        hints: [
          {
            content: 'placeme.gravity_model_tile.hint_1_content',
            example: 'placeme.gravity_model_tile.hint_1_example',
          },
          {
            content: 'placeme.gravity_model_tile.hint_2_content',
            example: 'placeme.gravity_model_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'potential',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_gravity_model.png?alt=media&token=00ea85d2-3223-4705-a429-7ce4a8fe57ad',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_gravity_model_en.png?alt=media&token=f7b23a3e-702d-4a5a-bc53-3ca931c912ab',
        },
      },
      {
        id: 'local_market_share',
        component: LocalMarketShareTile,
        canChooseRange: false,
        isExtraPaid: false,
        chosenRange: {
          id: 'line1000',
          type: 'line',
          value: 1000,
        },
        helpers: ['placeme.local_market_share_tile.helper_1'],
        label: 'placeme.tile.local_market_share',
        labelHint: 'placeme.label_hint.local_market_share',
        hints: [
          {
            content: 'placeme.local_market_share_tile.hint_1_content',
            example: 'placeme.local_market_share_tile.hint_1_example',
          },
          {
            content: 'placeme.local_market_share_tile.hint_2_content',
            example: 'placeme.local_market_share_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'potential',
        demoImages: {
          pl: '',
          en: '',
        },
      },
    ],
    icon:
  <PotentialIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'surroundings',
    label: 'placeme.category.surroundings',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'buildings',
        canChooseRange: true,
        isExtraPaid: false,
        component: BuildingsTile,
        helpers: ['placeme.buildings_tile.helper_1'],
        label: 'placeme.tile.buildings',
        labelHint: 'placeme.label_hint.buildings',
        maxRanges:  {
          car: 10,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: 150000000,
        },
        hints: [
          {
            content: 'placeme.buildings_tile.hint_1_content',
            example: 'placeme.buildings_tile.hint_1_example',
          },
          {
            content: 'placeme.buildings_tile.hint_2_content',
            example: 'placeme.buildings_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'surroundings',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_buildings.png?alt=media&token=ce46ac21-f37f-45b9-b4db-3dec45d43517',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_buildings_en.png?alt=media&token=a17a8cb8-eb3c-40c8-87b3-7f33eecb1d1c',
        },
      },
      {
        id: 'investments',
        component: InvestmentsTile,
        canChooseRange: true,
        isExtraPaid: false,
        helpers: ['placeme.investments_tile.helper_1'],
        label: 'placeme.tile.investment_plans',
        labelHint: 'placeme.label_hint.investment_plans',
        maxRanges: {
          car: 10,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: 150000000,
        },
        hints: [
          {
            content: 'placeme.investments_tile.hint_1_content',
            example: 'placeme.investments_tile.hint_1_example',
          },
          {
            content: 'placeme.investments_tile.hint_2_content',
            example: 'placeme.investments_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'surroundings',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_investments.png?alt=media&token=e66884c1-aefa-480f-a759-70b81ef89b2d',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_investments_en.png?alt=media&token=7a29eede-0fdd-4b19-9cff-8552ff85831e',
        },
      },
      {
        id: 'employees_in_range',
        component: EmployeesInRangeTile,
        canChooseRange: true,
        isExtraPaid: false,
        helpers: ['placeme.employees_in_range_tile.helper_1'],
        label: 'placeme.tile.employees_in_range',
        labelHint: 'placeme.label_hint.employees_in_range',
        maxRanges: defaultMaxRanges,
        hints: [
          {
            content: 'placeme.employees_in_range_tile.hint_1_content',
            example: 'placeme.employees_in_range_tile.hint_1_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'surroundings',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_workers.png?alt=media&token=7647195b-9712-40fc-a483-deb7559fbd0c',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_employees_in_range_en.png?alt=media&token=f3fc78cf-57fa-45d9-b723-595b2cec9f6b',
        },
      },
    ],
    icon:
  <SurroundingsIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'traffic',
    label: 'placeme.category.traffic',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'traffic_visualisation',
        canChooseRange: true,
        isExtraPaid: false,
        component: TrafficVisualisationTile,
        helpers: ['placeme.traffic_visualisation_tile.helper_1'],
        label: 'placeme.tile.traffic_visualisation',
        labelHint: 'placeme.label_hint.traffic_visualisation',
        maxRanges:  {
          car: 10,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: 150000000,
        },
        hints: [
          {
            content: 'placeme.traffic_visualisation_tile.hint_1_content',
            example: 'placeme.traffic_visualisation_tile.hint_1_example',
          },
          {
            content: 'placeme.traffic_visualisation_tile.hint_2_content',
            example: 'placeme.traffic_visualisation_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'traffic',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_traffic_visualisation.png?alt=media&token=5411a75e-b7d3-46a1-abaa-7abd9c56d10f',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_traffic_visualisation_en.png?alt=media&token=ae525616-844c-48a3-b817-ad89a9eaa497',
        },
      },
      {
        id: 'visiting_hours',
        component: VisitingHoursTile,
        canChooseRange: true,
        isExtraPaid: false,
        helpers: ['placeme.visiting_hours_tile.helper_1'],
        label: 'placeme.tile.visiting_hours',
        labelHint: 'placeme.label_hint.visiting_hours',
        maxRanges:theMostUsedMaxRanges,
        hints: [
          {
            content: 'placeme.visiting_hours_tile.hint_1_content',
            example: 'placeme.visiting_hours_tile.hint_1_example',
          },
          {
            content: 'placeme.visiting_hours_tile.hint_2_content',
            example: 'placeme.visiting_hours_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'traffic',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_visiting_hours.png?alt=media&token=0534d587-0797-4ad5-8c66-813d2568a01c',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_visiting_hours_en.png?alt=media&token=44590e9a-1ca6-48a6-91d4-5826673991b3',
        },
      },
      {
        id: 'customer_origin',
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        component: CustomerOriginTile,
        helpers: ['placeme.customer_origin_tile.helper_1'],
        label: 'placeme.tile.customer_origin',
        labelHint: 'placeme.label_hint.customer_origin',
        hints: [
          {
            content: 'placeme.customer_origin_tile.hint_1_content',
            example: 'placeme.customer_origin_tile.hint_1_example',
          },
          {
            content: 'placeme.customer_origin_tile.hint_2_content',
            example: 'placeme.customer_origin_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'traffic',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_customer_origin.png?alt=media&token=0a5958ee-c27f-4405-bdce-f258409dd7cd',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_customer_origin_en.png?alt=media&token=7e86d71e-e7bf-4578-83a1-7cd4d485a0b0',
        },
      },
    ],
    icon:
  <TrafficIcon
    className='traffic-icon'
    height={20}
    width={20}
  />,
  },
  {
    id: 'visualization',
    label: 'placeme.category.visualisation',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'cities_population',
        component: CitiesPopulationTile,
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.cities_population_tile.helper_1'],
        label: 'placeme.tile.cities_population',
        labelHint: 'placeme.label_hint.cities_population',
        hints: [
          {
            content: 'placeme.cities_population_tile.hint_1_content',
            example: 'placeme.cities_population_tile.hint_1_example',
          },
          {
            content: 'placeme.cities_population_tile.hint_2_content',
            example: 'placeme.cities_population_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'visualization',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_cities_population.png?alt=media&token=86be7d7c-892d-46b4-83e4-7ce28a2fe2e4',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_people_map_en.png?alt=media&token=52259fbb-57a6-4615-85cb-8776dfea2da1',
        },
      },
      {
        id: 'population_heatmap',
        component: PopulationHeatmapTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.population_heatmap_tile.helper_1'],
        label: 'placeme.tile.population_heatmap',
        labelHint: 'placeme.label_hint.population_heatmap',
        hints: [
          {
            content: 'placeme.people_tile.hint_1_content',
            example: 'placeme.people_tile.hint_1_example',
          },
          {
            content: 'placeme.people_tile.hint_2_content',
            example: 'placeme.people_tile.hint_2_example',
          },
          {
            content: 'placeme.people_tile.hint_3_content',
            example: 'placeme.people_tile.hint_3_example',
          },
          {
            content: 'placeme.people_tile.hint_4_content',
            example: 'placeme.people_tile.hint_4_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'visualization',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_population_heatmap.png?alt=media&token=c8a7fcec-6261-43cb-aaa1-8fa07e93bc28',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_population_heatmap_en.png?alt=media&token=b8f59627-c426-4a73-ac7e-8fb4631022e2',
        },
      },
      {
        id: 'traffic_visualisation_map',
        component: TrafficVisualisationMapTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges:  {
          car: 10,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: 150000000,
        },
        helpers: ['placeme.traffic_visualisation__map_tile.helper_1'],
        label: 'placeme.tile.traffic_visualisation_map',
        labelHint: 'placeme.label_hint.traffic_visualisation_map',
        hints: [
          {
            content: 'placeme.traffic_visualisation__map_tile.hint_1_content',
            example: 'placeme.traffic_visualisation__map_tile.hint_1_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'visualization',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_traffic_map.png?alt=media&token=6362e434-931c-4de6-b7da-843d8576ca67',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_traffic_visualisation_map_en.png?alt=media&token=dbf588d7-1f0b-4249-b365-f262b42cf079',
        },
      },
      {
        id: 'land_use_plan',
        component: LandUsePlanTile,
        canChooseRange: false,
        isExtraPaid: false,
        helpers: ['placeme.land_use_plan_tile.helper_1'],
        label: 'placeme.tile.land_use_plan',
        labelHint: 'placeme.label_hint.land_use_plan',
        maxRanges:theMostUsedMaxRanges,
        hints: [
          {
            content: 'placeme.land_use_plan_tile.hint_1_content',
            example: 'placeme.land_use_plan_tile.hint_1_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'visualization',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_land_use_plan.png?alt=media&token=9b974b78-d286-4666-874e-96fc862d366b',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_land_use_plan_en.png?alt=media&token=96e9825b-5098-4a7e-ad3d-2f63c93d6e89',
        },
      },
      {
        id: 'satellite',
        component: SatelliteTile,
        canChooseRange: false,
        isExtraPaid: false,
        helpers: ['placeme.satellite_tile.helper_1'],
        label: 'placeme.tile.satellite',
        labelHint: 'placeme.label_hint.satellite',
        hints: [],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'visualization',
        demoImages: {
          pl: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_satellite.png?alt=media&token=bafaac61-4c0a-42a4-8d63-d281ff3661e1',
          en: 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/demo_tile_images%2Fdemo_satellite_en.png?alt=media&token=7b275046-6df8-4a69-850c-ab47dd6e0c5d',
        },
      },
    ],
    icon:
  <VisualizationIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'big_ben',
    label: 'placeme.category.big_ben',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'population_big_ben',
        component: PopulationAndSpendingTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges: {
          car: 10,
          walk: 25,
          bike: 15,
          line: 3000,
          custom:  260000000,
        },
        helpers: ['placeme.population_and_spending_tile.helper_1'],
        label: 'placeme.tile.population_and_spending',
        labelHint: 'placeme.label_hint.population_and_spending',
        hints: [],
        dataSetUpdates: [
          TrafficDataSet,
        ],
        section: 'big_ben',
      },
      {
        id: 'traffic_big_ben',
        component: TrafficTile,
        canChooseRange: true,
        helpers: ['placeme.big_ben_traffic_tile.helper_1'],
        label: 'placeme.tile.big_ben_traffic',
        labelHint: 'placeme.label_hint.big_ben_traffic',
        isExtraPaid: false,
        maxRanges: {
          car: 10,
          walk: 25,
          bike: 15,
          line: 3000,
          custom:  260000000,
        },
        hints: [],
        dataSetUpdates: [
          TrafficDataSet,
        ],
        section: 'big_ben',
      },
      {
        id: 'pois_big_ben',
        component: CompetitionAndSurroundingsTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges: {
          car: 10,
          walk: 25,
          bike: 15,
          line: 3000,
          custom:  260000000,
        },
        helpers: ['placeme.competition_and_surroundings_tile.helper_1'],
        label: 'placeme.tile.competition_and_surroundings',
        labelHint: 'placeme.label_hint.competition_and_surroundings',
        hints: [],
        dataSetUpdates: [
          TrafficDataSet,
        ],
        section: 'big_ben',
      },
      {
        id: 'look_alike_big_ben',
        component: SimilarLocationTile,
        canChooseRange: false,
        isExtraPaid: false,
        haveToAccept: true,
        acceptDescription: 'placeme.similar_location.info',
        helpers: ['placeme.similar_location_tile.helper_1'],
        label: 'placeme.tile.similar_location',
        labelHint: 'placeme.label_hint.similar_location',
        hints: [],
        dataSetUpdates: [
          TrafficDataSet,
        ],
        section: 'big_ben',
      },
      {
        id: 'scoring_model_big_ben',
        component: PotentialTile,
        canChooseRange: false,
        isExtraPaid: false,
        helpers: ['placeme.big_ben_potential_tile.helper_1'],
        label: 'placeme.tile.big_ben_potential',
        labelHint: 'placeme.label_hint.big_ben_potential',
        hints: [],
        dataSetUpdates: [
          TrafficDataSet,
        ],
        section: 'big_ben',
      },
    ],
    icon:
  <BigBenIcon
    height={20}
    width={20}
  />,
  },
]
