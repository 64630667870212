/* eslint-disable max-lines */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '@dataplace.ai/features'
import { fetchWorkspaceUsageValue, saveTileData, saveTiles } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { config } from 'apps/placeme/src/config'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { NestedSelect, Pagination } from '@dataplace.ai/ui-components/molecules'
import { Checkbox, ImageWithFallback, Tooltip } from '@dataplace.ai/ui-components/atoms'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import AddMoreData from '../../../../molecules/AddMoreData/AddMoreData'
import { ICompetitionAndSurrounding, ICompetitionAndSurroundingsTileData } from './@types/ICompetitionAndSurroundingsTileData'
import { IPois } from '../../Market/Pois/@types/IPoisTileData'
import { IInvestment } from '../../Surroundings/Investments/@types/IInvestmentsTileData'
import { investmentsTableData, poisTableData } from './data'
import { getInvestmentIcon } from '../../Surroundings/Investments/InvestmentsTile'
import { ReactComponent as ClockIcon } from '../../../../../../../../../../libs/shared/assets/src/lib/icons/clock.svg'
import { sortCategories, sortCategoriesNestedSelectList } from '../../Market/Pois/functions'
import { ReportOutOfDatePoiSection } from '../../../../molecules'

const { v4: uuidv4 } = require('uuid')

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})

const Section = styled.div(({ theme }) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: ${palette.light.white};
    border: 1px solid ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    color: ${palette.black};
    margin-bottom: 30px;

    >ul {
      width:100%;
      list-style-type: none;
      
      >li{
        font-size: ${typography.small.pt_13_regular.fontSize};
        font-weight: ${typography.small.pt_13_regular.fontWeight};
        line-height: ${typography.small.pt_13_regular.lineHeight};
        padding: 10px 0 10px 10px;
        width:100%;
        border-radius: 6px;
      }

      >li:nth-child(2n){
          background-color: ${palette.light.main};
        }
    }

    > h4 {
      font-size: ${typography.main.pt_15_medium_upper.fontSize};
      font-weight: ${typography.main.pt_15_medium_upper.fontWeight};
      line-height: ${typography.main.pt_15_medium_upper.lineHeight};
      letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
      text-transform: ${typography.main.pt_15_medium_upper.textTransform};
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    > h5 {
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
    }

    > p {
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
      margin-bottom: 15px;

      > span {
        font-size: ${typography.big.pt_28_semibold.fontSize};
        font-weight: ${typography.big.pt_28_semibold.fontWeight};
        line-height: ${typography.big.pt_28_semibold.lineHeight};
        font-family: 'Inter',sans-serif;
        display: inline;
        margin-left: 0.25rem;
      }
    }

    .p15{
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
      margin-bottom: 15px;
    }

    > span {
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
      margin-bottom: 10px;

      > strong {
        font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
        font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
        line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
        letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
        text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
        display: block;
        margin-top: 10px;
      }
    }
  `
})

const NestedSelectWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > span {
      margin-right: 0.5rem;
    }
  `
})

const CheckboxWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
      display: flex;
      margin: 1rem 0;
      align-items: center;
      color: ${palette.blue};
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};

      > :first-child {
        margin-right: 0.5rem
      }
    `
})

const AdditionalInfo = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    padding: 0.5rem;
    border-top: 1px solid ${palette.light.darker};
    flex-direction: row;
    flex-wrap: wrap;

    div {
      display: flex;
      flex-direction: column;
      margin: 0.5rem 0;
    }

    div:first-child {
      width: 100%;
    }

    > div {
      width: 50%;
      flex-direction: column;
    }

    > div:last-child {
      width: 100%;
      flex-direction: column;
    }    

    > div > span:first-child,
    > div > div > span:first-child,
    > div > div > div > span:first-child {
      margin-bottom: 0.25rem;
      color: ${palette.dark.normal};
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
    }

    > div > div > span,
    > div > div > div > span {
      color: ${palette.black};
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: ${typography.small.pt_13_semibold.fontWeight};
      line-height: ${typography.small.pt_13_semibold.lineHeight};
      letter-spacing: ${typography.small.pt_13_semibold.letterSpacing};
      text-transform: ${typography.small.pt_13_semibold.textTransform};
    }

    > div > span {
      color: ${palette.black};
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
      letter-spacing: ${typography.small.pt_13_regular.letterSpacing};
      text-transform: ${typography.small.pt_13_regular.textTransform};
    }
  `
})
const ObjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const ScrollWrapper = styled.div`
  margin: 1rem 0;
  max-height: 650px;
  overflow-y: auto;
  overscroll-behavior: contain;

-ms-overflow-style: none;  /* IE and Edge */
  .ps{
    overflow: visible !important;
  }
`

const TitleWithLegend = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin: 1rem 0 0;
    display: flex;
    flex-direction: row;
    color: ${palette.black};
    background-color: ${palette.light.white};
    font-size: ${typography.main.pt_15_semibold.fontSize};
    font-weight: ${typography.main.pt_15_semibold.fontWeight};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
    > img{
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }
  `
})

const NoItems = styled.div`
  width:100%;
  display: flex;
  justify-content: center;
  text-align: center;
`
interface ExpandableProps {
  investorName: string,
  legalStatus?: string,
  dateOfApplication: string,
  capacity: number | string,
  info: string
}

const Expandable: React.FC<ExpandableProps> = ({
  investorName, legalStatus, dateOfApplication, capacity, info,
}) => {
  const { t } = useTranslation()

  return (
    <AdditionalInfo>
      <div>
        <div>
          <span>{`${t('placeme.investments_tile.investor_name')}:`}</span>
          <span>
            {(typeof investorName === 'string' && (investorName.toLowerCase() === 'none'
          || investorName.toLowerCase() === 'nan'))
              ? '-'
              : investorName}

          </span>
        </div>
        { legalStatus && (
          <div>
            <span>{`${t('placeme.investments_tile.legal_status')}:`}</span>
            <span>{legalStatus}</span>
          </div>
        )}
        { !legalStatus && (
          <div>
            <span>{`${t('placeme.investments_tile.info')}:`}</span>
            <span>{info}</span>
          </div>
        )}
      </div>
      <div>
        <div>
          <span>{`${t('placeme.investments_tile.date_of_application')}:`}</span>
          <span>{dateOfApplication}</span>
        </div>
        <div>
          <span>{`${t('placeme.investments_tile.capacity')}:`}</span>
          <span>
            {(typeof capacity === 'string' && (capacity.toLowerCase() === 'none'
          || capacity.toLowerCase() === 'nan'))
              ? '-'
              : `${capacity} m2`}

          </span>
        </div>
      </div>

      <div>
        {legalStatus && (
          <>
            <span>{`${t('placeme.investments_tile.info')}:`}</span>
            <span>{info}</span>
          </>
        )}
      </div>

    </AdditionalInfo>
  )
}

export const CompetitionAndSurroundingsTile: React.FC<{
  data: ICompetitionAndSurroundingsTileData, tileId: string}> = ({
  data, tileId,
}) => {
  // variables
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const authContext = useContext(AuthContext)
  const {
    values, canBeSave,
  } = useSelector((state: RootState) => state.analysis)
  const {
    nonGroupedLabels, groupedLabels,
  } = poisTableData

  // states
  const [token, setToken] = useState('')
  const [initialData, setInitialData] = useState<ICompetitionAndSurrounding | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  // pois
  const [grouped, setGrouped] = useState<boolean>(true)
  const [categories, setCategories] = useState<ResourceWithId[]>([])
  const [val, setValue] = useState<ResourceWithId[]>(categories)
  const [rowsToDisplay, setRowsToDisplay] = useState<{[key: string]: IPois[] }>({})
  const labels = grouped ? groupedLabels : nonGroupedLabels

  // investments
  const [categoriesInvestments, setCategoriesInvestments] = useState<ResourceWithId[]>([])
  const [valInvestments, setValueInvestments] = useState<ResourceWithId[]>(categoriesInvestments)
  const [valuesToDisplay, setValuesToDisplay] = useState<{[key: string]: IInvestment[]}>({})
  const { labelsInvestments } = investmentsTableData

  // functions
  const getTileType = (id: string) => id.split('-')[0]

  const catchmentId = values?.find(c => c.id === 'big_ben')?.tiles?.find(t =>
    t.id === tileId)?.chosenRange?.catchmentId

  const fetchData = useCallback(async (addData?:ICompetitionAndSurrounding) => {
    if (token.length) {
      const catchmentId = values?.find(c => c.id === 'big_ben')?.tiles?.find(t => t.id === tileId)?.chosenRange?.catchmentId

      let body
      if (addData) {
        body = {
          catchmentId,
          extra: true,
        }
      } else {
        body = {
          catchmentId,
        }
      }

      const endpoint = Object.entries(ENDPOINTS).find((key) => key[0] === `${getTileType(tileId).toUpperCase()}_TILE`)?.[1]
      let saveData

      try {
        const response = await getAxios(config.API_URL, token).post(endpoint || '', body)
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
        if (response.status === 204) {
          window?.localStorage.setItem('noDataModal', catchmentId || 'no catchment')
          window?.dispatchEvent(new CustomEvent('noDataModal'))
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        saveData = {
          loading: false,
          error: e.message,
          value: null,
        }
      }
      finally {
        if (!addData) {
          dispatch(saveTileData('big_ben', tileId, saveData))
        } else {
          dispatch(saveTileData('big_ben', tileId, {
            ...data,
            value: {
              ...addData,
              additionalData: saveData?.value?.additionalData,
            },
          }))
        }
      }
    }
  }, [token])

  // hooks
  useEffect(() => {
    if (token.length && !data?.mapLocation) {
      dispatch(fetchWorkspaceUsageValue(token))
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData.user?.getIdToken(true)?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (!data?.value) fetchData()
  }, [fetchData, !data?.value])

  useEffect(() => {
    if (data?.value) {
      if (data?.value?.additionalData) {
        setInitialData(undefined)
      }
      else {
        setInitialData(data?.value)
      }
    }
  }, [data])

  // pois
  useEffect(() => {
    if (data?.value?.additionalData?.pois) {
      // save categories for pdf
      const toExclude = categories?.filter(cat =>
        !val?.map(item => item?.id).includes(cat?.id)).map(item => item?.id)
      if (catchmentId && toExclude?.length) {
        const pdfTiles = values?.map(cat => {
          if (cat?.id === 'big_ben') {
            return {
              ...cat,
              tiles: cat?.tiles.map(tile => {
                if (tile?.chosenRange?.catchmentId === catchmentId) {
                  return {
                    ...tile,
                    pdfExcluded: toExclude,
                  }
                } return tile
              }),

            }
          }
          return cat
        })
        dispatch(saveTiles(pdfTiles))
      }

      // filtering results by selected categories
      const filteredValues = data?.value?.additionalData?.pois.filter(value => val
        .map(c => c.id).includes(value.category))
      // parsing an array to object by category
      const assignedValues = filteredValues.reduce((acc, cur) => ({
        ...acc,
        [cur.category]: acc?.[cur.category]?.length ? [...acc?.[cur.category], cur] : [cur],
      }), ({} as {[key: string]: IPois[] }))
      // grouping results by brand and counting
      const groupedValues = Object.entries(assignedValues).reduce((acc, cur) => {
        const grouped = cur[1].reduce((acc: IPois[], cur: IPois) => {
          const exist = acc.find(v => v.brand === cur.brand)
          const reducedValue = {
            ...cur,
            count: exist?.count ? exist.count + 1 : 1,
            distance: exist?.distance ? Math.min(exist.distance, cur.distance) : cur.distance,
          }
          let newArray = []
          if (exist) {
            acc.forEach(item => {
              if (item !== exist) newArray.push(item)
            })
            newArray.push(reducedValue)
          } else {
            newArray = [...acc, reducedValue]
          }

          return newArray
        }, [])

        return {
          ...acc,
          [cur[0]]: grouped,
        }
      }, {})

      // setting the results to be displayed depending on whether they are to be grouped or not
      setRowsToDisplay(grouped
        ? sortCategories(groupedValues)
        : sortCategories(filteredValues.reduce((acc, cur) => ({
          ...acc,
          [cur.category]: acc?.[cur.category]?.length ? [...acc?.[cur.category], cur] : [cur],
        }), ({} as {[key: string]: IPois[] }))))
    }
  }, [JSON.stringify(data), grouped, val])

  useEffect(() => {
    if (data?.value) {
      const categoriesObjects : { id: string, content: string}[] = []
      data?.value?.additionalData?.pois?.forEach(investment => {
        const categoryId = investment?.category
        if (!categoriesObjects.find(cat => cat?.id === categoryId)) {
          categoriesObjects.push({
            id: categoryId,
            content: t(`placeme.pois_tile.category.${categoryId.toLocaleLowerCase().split(/[_ -]+/)
              .join('-')}`),
          })
        }
      })

      setCategories(sortCategoriesNestedSelectList(categoriesObjects))
      setValue(sortCategoriesNestedSelectList(categoriesObjects))
    }
  }, [data?.value?.additionalData?.pois])

  // investments
  useEffect(() => {
    if (data?.value?.additionalData?.investments) {
      const categoriesObjects : { id: string, content: string}[] = []
      data?.value?.additionalData?.investments?.forEach(investment => {
        const categoryId = investment?.category === 'nan' ? 'other' : investment?.category
        if (!categoriesObjects.find(cat => cat?.id === categoryId)) {
          categoriesObjects.push({
            id: categoryId,
            content: t(`placeme.investments_tile.category.${categoryId?.toLocaleLowerCase().split(/[_ -]+/)
              .join('_')}`),
          })
        }
      })

      setCategoriesInvestments(categoriesObjects)
      setValueInvestments(categoriesObjects)
    }
  }, [data?.value?.additionalData?.investments])

  useEffect(() => {
    if (data?.value?.additionalData?.investments?.length) {
      // save categories for pdf
      const toExclude = categories?.filter(cat =>
        !val?.map(item => item?.id).includes(cat?.id)).map(item => item?.id)
      if (catchmentId && toExclude?.length) {
        const pdfTiles = values?.map(cat => {
          if (cat?.id === 'big_ben') {
            return {
              ...cat,
              tiles: cat?.tiles.map(tile => {
                if (tile?.chosenRange?.catchmentId === catchmentId) {
                  return {
                    ...tile,
                    pdfExcluded: toExclude,
                  }
                } return tile
              }),

            }
          }
          return cat
        })
        dispatch(saveTiles(pdfTiles))
      }

      const sortedValues: {[key: string]: IInvestment[]} = {}

      data?.value?.additionalData?.investments?.filter(v =>
        valInvestments.map(v => v.id)?.includes(v.category)).forEach(invest => {
        if (!sortedValues[invest?.category]) {
          sortedValues[invest?.category] = []
        }
      })
      data?.value?.additionalData?.investments?.filter(v => valInvestments.map(v => v.id)?.includes(v.category))
        ?.sort((a, b) => (a.distanceFromPoint < b.distanceFromPoint ? -1 : 1))
        ?.forEach(v => sortedValues[v.category].push(v))

      setValuesToDisplay(sortedValues || [])
    }
  }, [valInvestments])

  return !data || data?.loading
    ? (<Loader />)
    : (
      <Wrapper>
        {/* competition */}
        <Section>
          <h4>{t('placeme.competition_and_surrounding.section_title_1')}</h4>
          <p>
            {t('placeme.competition_and_surrounding.competition.line_1')}
            {' '}
            <span>{data?.value?.competition?.length}</span>
          </p>
          <ul>
            {data?.value?.competition?.map(comp => <li key={comp?.pointId}>{`${comp?.name}, ${comp?.address}`}</li>)}
          </ul>
        </Section>
        {/* traffic conversion */}
        <Section>
          <h4>{t('placeme.competition_and_surrounding.section_title_2')}</h4>
          <p>
            {t('placeme.competition_and_surrounding.traffic_conversion.line_1')}
            {' '}
            <span>{`${data?.value?.trafficConversion * 100}%`}</span>
          </p>
          <span>{t('placeme.competition_and_surrounding.traffic_conversion.description_1')}</span>
        </Section>

        { !data?.value?.additionalData
        && canBeSave
        && (
          <AddMoreData
            loading={loading}
            onClick={() => {
              setLoading(true)
              fetchData(initialData)
            }}
            tiles={['placeme.competition_and_surrounding.section_title_3', 'placeme.competition_and_surrounding.section_title_4', 'placeme.competition_and_surrounding.section_title_5']}
          />
        )}
        {data?.value?.additionalData && (
          <>
            {/* gravity model */}
            <Section>
              <h4>{t('placeme.competition_and_surrounding.section_title_3')}</h4>
              { data?.value?.additionalData?.probability
                ? (
                  <>
                    <p>
                      {t('placeme.competition_and_surrounding.gravity_model.line_1')}
                      {' '}
                      <span>{`${data?.value?.additionalData?.probability?.estimatedNumberOfClients}`}</span>
                    </p>
                    <p className='p15'>{t('placeme.competition_and_surrounding.gravity_model.description_1')}</p>
                    <p>
                      {t('placeme.competition_and_surrounding.gravity_model.line_2')}
                      {' '}
                      <span>{`${data?.value?.additionalData?.probability?.choiceProbability * 100}%`}</span>
                    </p>
                    <span>{t('placeme.competition_and_surrounding.gravity_model.description_2')}</span>
                  </>
                )
                : <NoItems>{t('placeme.competition_and_surrounding.gravity_model.no_items')}</NoItems>}
            </Section>
            {/* pois */}
            <Section>
              <h4>{t('placeme.competition_and_surrounding.section_title_4')}</h4>
              {data?.value?.additionalData?.pois?.length
                ? (
                  <>
                    <NestedSelectWrapper>
                      <span>{t('placeme.pois_tile.points_categories')}</span>
                      <NestedSelect
                        name=''
                        onChange={setValue}
                        options={categories}
                        selected={val}
                        width='50%'
                      />
                    </NestedSelectWrapper>
                    <CheckboxWrapper>
                      <Checkbox
                        defaultChecked={grouped}
                        onClick={() => setGrouped(state => !state)}
                      />
                      <span>{t('placeme.pois_tile.group')}</span>
                    </CheckboxWrapper>
                    {/* report outdated poi modal section */}
                    {catchmentId && <ReportOutOfDatePoiSection catchmentId={catchmentId} />}
                    { Object.entries(rowsToDisplay)?.map((entry) => (
                      <Table
                        key={entry[0]}
                        content={entry[1].map(value => [
                          <ImageWithFallback
                            key={value.logo}
                            alt={value.brand}
                            fallbackSrc='assets/icons/logoPlaceholder.svg'
                            height='22px'
                            src={value.logo}
                            width='22px'
                          />,
                          <span
                            key={value.brand + value.address}
                            className={value?.workingHours ? 'bank' : undefined}
                          >
                            <Tooltip
                              content={value.brand}
                              position='right center'
                            >
                              {' '}
                              {value.brand}
                            </Tooltip>
                            {value?.workingHours
                              ? (
                                <Tooltip
                                  content={(
                                    <ul>
                                      {Object.entries(value?.workingHours).map(([key, value]) => <li key={key}>{`${t(`placeme.pois.working_hours.day.${key}`)}: ${value}`}</li>)}
                                    </ul>
                                  )}
                                  header={t('placeme.pois.working_hours')}
                                  position='right center'
                                >
                                  <ClockIcon
                                    height='18px'
                                    width='18px'
                                  />
                                </Tooltip>
                              )
                              : null}
                          </span>,
                          <span key={value.address}>
                            {grouped
                              ? value.count
                              : (
                                <Tooltip
                                  content={value.address}
                                  position='right center'
                                >
                                  {value.address}
                                </Tooltip>
                              )}
                          </span>,
                          <span
                            key={value.distance}
                            style={{
                              display: 'flex',
                              justifyContent:'flex-end',
                            }}
                          >
                            {value.distance * 1000}
                          </span>,
                        ])}
                        gap='1rem'
                        headerTemplate='1fr 6fr 4fr 4fr'
                        labels={[
                          <span
                            key={entry[0]}
                            style={{
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t(`placeme.pois_tile.category.${entry[0]?.toLocaleLowerCase().split(/[_ -]+/)
                              .join('-')}`)}
                          </span>,
                          ...labels
                            .map((label: string) => (<span key={label}>{t(label)}</span>)),
                        ]}
                        pois={grouped}
                        rowTemplate={grouped ? '1fr 6fr 4fr 4fr' : '1fr 3fr 7fr 4fr'}
                      />
                    ))}
                  </>
                )
                : <NoItems>{t('placeme.competition_and_surrounding.pois.no_items')}</NoItems>}
            </Section>
            {/* investments */}
            <Section>
              <h4>{t('placeme.competition_and_surrounding.section_title_5')}</h4>
              {data?.value?.additionalData?.investments?.length
                ? (
                  <>
                    <NestedSelectWrapper>
                      <span>{t('placeme.investments_tile.investment_categories')}</span>
                      <NestedSelect
                        name=''
                        onChange={setValueInvestments}
                        options={categoriesInvestments}
                        selected={valInvestments}
                        width='50%'
                      />
                    </NestedSelectWrapper>
                    { Object.entries(valuesToDisplay).map((v) => (
                      <ObjectWrapper key={uuidv4()}>
                        <TitleWithLegend>
                          <img
                            alt=''
                            src={getInvestmentIcon(v[0])}
                          />
                          {t(`placeme.investments_tile.category.${v[0].split(' ').join('_')}`)}
                        </TitleWithLegend>
                        <ScrollWrapper>
                          <PerfectScrollbar
                            className='scroll'
                          >
                            <Pagination
                              data={(v[1].map(val => [(val?.name?.toLowerCase() === 'nan') ? '-' : val?.name,
                                val?.address, val?.distanceFromPoint * 1000,
                                val?.capacity, val?.dateOfApplication, val?.info, val?.investorName,
                                val?.legalStatus])) as string[][]}
                              dataLimit={8}
                              justifyNavigation='center'
                              renderComponent={(data) => {
                                const newData = data as string[]
                                return (
                                  <Table
                                    content={newData.map(d => [<span key={uuidv4()}>{d[0]}</span>,
                                      <span key={uuidv4()}>{d[1]}</span>,
                                      <span key={uuidv4()}>{`${d[2]} m`}</span>])}
                                    expandableComponent={newData.map(item => (
                                      <Expandable
                                        key={uuidv4()}
                                        capacity={item[3]}
                                        dateOfApplication={item[4]}
                                        info={item[5]}
                                        investorName={item[6]}
                                      // legalStatus={item[7]}
                                      />
                                    ))}
                                    gap='1rem'
                                    headerTemplate='5fr 3fr 4fr 1fr'
                                    headerTextAlign='left'
                                    labels={labelsInvestments.map((label) => (
                                      <span key={label}>{t(label)}</span>
                                    ))}
                                    rowTemplate='5fr 3fr 4fr 1fr'
                                    whiteSpaces='wrap'
                                  />
                                ) }}
                            />
                          </PerfectScrollbar>
                        </ScrollWrapper>
                      </ObjectWrapper>
                    ))}
                  </>
                )
                : <NoItems>{t('placeme.competition_and_surrounding.investments.no_items')}</NoItems>}
            </Section>
          </>
        )}
      </Wrapper>
    )
}
