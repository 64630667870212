/* eslint-disable max-lines,import/no-cycle,@nrwl/nx/enforce-module-boundaries */
import React, { FC, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import 'rc-slider/assets/index.css'
import {
  TextButton,
  VerticalDivider,
  LinkWithIconDelete,
} from '@dataplace.ai/ui-components/atoms'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { useSelector } from 'react-redux'
import { RangeType } from '@dataplace.ai/types'
import { ReactComponent as MarkOnMapIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/rangeIcons/markOnMap.svg'
import { ReactComponent as MapIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/rangeIcons/map.svg'
import { ICustomRangeModalBarProps } from './@types/ICustomRangeModalBar.props'
import { RangeSlider } from '../../atoms'
import { handleRangeIcon } from '../../organisms'
import { fetchTemporaryCatchment, saveTemporaryCatchment } from '../../../slice/analysisSlice'
import { RootState } from '../../../../../redux/store'

const { saveAs } = require('file-saver')
const domToImage = require('dom-to-image')

const StyledTextButton = styled(TextButton)(({ theme }) => {
  const { typography } = theme
  return css`
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
      path {
        stroke: white;
      }
    }

    p {
      color: white;
      font-size: ${typography.small.pt_13_medium.fontSize};
      font-weight: ${typography.small.pt_13_medium.fontWeight};
      line-height: ${typography.small.pt_13_medium.lineHeight};
    }
  `
})
const StyledLinkWithIconDelete = styled(LinkWithIconDelete)(({ theme }) => {
  const { palette } = theme
  return css`
    background-color: ${palette.dark.main};
    padding: 10px 15px;
    border-radius: 0px 6px 6px 0px;
    min-height:41px;

    :hover{
        color: ${palette.light.white};
        background-color: ${palette.results.red.light};
        > svg > path {
          stroke: ${palette.light.white};
        }
    }
  `
})
const GreenTextButton = styled(StyledTextButton)(({ theme }) => {
  const { palette } = theme
  return css`
    padding: 10px 15px;
    background-color:transparent ;
    border-radius: 6px;

    :disabled{
      border-radius: 6px 0px 0px 6px;
      background-color: ${palette.results.green.dark};
    }
  `
})
const StyledGreenTextButton = styled(GreenTextButton)(({ theme }) => {
  const { palette } = theme

  return css`
  margin-left: 21px;
  background-color:${palette.results.green.dark};
  :disabled{
    background-color:transparent ;
    p{
      color: ${palette.product.location.medium};
    }
  }
`
})
const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme

  return css`
    min-height: 52px;
    border-radius: 12px 12px 0 0;
    background-color: ${palette.product.location.main};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px 10px 15px;
  `
})

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
`

const RangeTypeButton = styled.button<{active: boolean}>(({
  theme, active,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
    cursor: pointer;
    background: ${palette.dark.main};
    border: none;

    :first-child{
      border: 1px solid ${palette.product.location.main};
      border-radius: 6px 0 0 6px;
    }

    :nth-child(3){
      border-left: 1px solid ${palette.product.location.main};
    }

    :nth-child(4){
      border: 1px solid ${palette.product.location.main};
      border-radius: 0 6px 6px 0;
    }

    p {
      font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
    }

    svg {
      margin: 0 .3rem;
        path{
          fill: white;
        }
    }

    :disabled{
      svg{
        path{
          fill: ${palette.product.location.medium}
        }
      }
    }

    ${active && css`
      background: ${palette.results.green.dark};
      box-sizing: border-box;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
      color: white;
    `}
  `
})

export const CustomRangeModalBar: FC<ICustomRangeModalBarProps> = ({
  customRange,
  setCustomRange,
  onlyMap,
  setRemoveCustomLayers,
  isDrawRangeChosen,
  setDrawRangeChoose,
  maxRanges,
  drawPolygon,
  isRangeDrawn,
  setIsRangeDrawn,
  tile,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)
  const { analytics } = useContext(AnalyticsContext)
  const { value } = useSelector((state: RootState) => state.location)
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)

  useEffect(() => {
    authContext.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  const deleteDrawPolygon = () => {
    setCustomRange(state => ({
      ...state,
      id: 'walk-8',
      type: 'walk',
      value: 8,
    }))

    const cancelPolygonButtons = document.body.getElementsByClassName(
      'leaflet-draw-actions',
    )

    const cancelButton = cancelPolygonButtons?.[2]
    if (cancelButton) {
    // find cancel leaflet draw button - todo - check if is another way after new realise of react-leaflet-draw
      const cancel = () => {
        if (cancelButton?.children?.item(2)?.children.item(0)) {
          return cancelButton?.children?.item(2)?.children.item(0) as HTMLElement
        }
        return <p>error</p> as unknown as HTMLParagraphElement
      }

      cancel().click()
      setDrawRangeChoose(false)
      setRemoveCustomLayers(true)
    }
  }

  const handleRangeTypeChange = (type: RangeType, value: number) => {
    setCustomRange(state => ({
      ...state,
      id: type + state.value,
      type,
      value,
    }))
  }

  const downloadMap = async (mapId: string) => {
    const mapContainer = document.getElementById(mapId)
    await domToImage
      .toBlob(mapContainer)
      .then((blob: { size: number; type: string }) => {
        saveAs(blob, `placeme-map--${moment().format('DD-MM-YYYY--HH-mm-ss')}.png`)
      })
  }
  const handleTypeButton = (type: RangeType, rangeValue: number) => {
    analytics?.track('Range Type Selected', {
      range: {
        type: type.split('-')[0],
        value,
      },
      location: {
        ...value,
      },
      tile: tile?.split('-')[0],
    })
    handleRangeTypeChange(type, rangeValue)
    setRemoveCustomLayers(true)
    if (isDrawRangeChosen) {
      deleteDrawPolygon()
    }
    dispatch(saveTemporaryCatchment(undefined))
    setIsRangeDrawn(false)
  }

  const handleRemoveLayerFromSlider = () => {
    if (isDrawRangeChosen) {
      deleteDrawPolygon()
    }
    setRemoveCustomLayers(true)
    dispatch(saveTemporaryCatchment(undefined))
    setIsRangeDrawn(false)
  }

  return (
    <Wrapper>
      <SelectWrapper>
        {!onlyMap && (
          <>
            <RangeTypeButton
              active={customRange.type === 'walk'}
              disabled={isDrawRangeChosen}
              onClick={() => {
                handleTypeButton('walk', 8)
              }}
            >
              {handleRangeIcon('walk', 15, 15)}
            </RangeTypeButton>
            <RangeTypeButton
              active={customRange.type === 'car'}
              disabled={isDrawRangeChosen}
              onClick={() => {
                handleTypeButton('car', 8)
              }}
            >
              {handleRangeIcon('car', 15, 15)}
            </RangeTypeButton>
            <RangeTypeButton
              active={customRange.type === 'bike'}
              disabled={isDrawRangeChosen}
              onClick={() => {
                handleTypeButton('bike', 8)
              }}
            >
              {handleRangeIcon('bike', 15, 15)}
            </RangeTypeButton>
            <RangeTypeButton
              active={customRange.type === 'line'}
              disabled={isDrawRangeChosen}
              onClick={() => {
                handleTypeButton('line', 1000)
              }}
            >
              {handleRangeIcon('line', 15, 15)}
            </RangeTypeButton>
          </>
        )}
        {!onlyMap && (
          <>
            <RangeSlider
              handleRemoveLayers={handleRemoveLayerFromSlider}
              maxRanges={maxRanges}
              setCustomRange={setCustomRange}
              tile={tile || ''}
              type={customRange.type}
            />
            <StyledGreenTextButton
              disabled={isDrawRangeChosen || isRangeDrawn}
              onClick={() => {
                analytics?.track('Show On Map Button Clicked', {
                  tile: tile?.split('-')[0],
                  range: {
                    type: customRange?.type,
                    value: customRange?.value,
                  },
                  location: {
                    ...value,
                  },
                })
                setIsRangeDrawn(true)
                dispatch(fetchTemporaryCatchment(token, {
                  length: customRange.value,
                  lat: value?.lat || 0,
                  lng: value?.lng || 0,
                  mode: customRange.type,
                }))
              }}
            >
              <p>{t('generic.see_on_map')}</p>
            </StyledGreenTextButton>
            {!(comparedLocation?.generatedFromNow || comparedLocation?.alreadyGenerated)
            && (
              <VerticalDivider
                height='2.5rem'
                margin='0 1.25rem'
              />
            )}
            {!isDrawRangeChosen
              ? (!(comparedLocation?.generatedFromNow || comparedLocation?.alreadyGenerated)
                && (

                  <GreenTextButton
                    onClick={() => {
                      drawPolygon()
                      analytics?.track('Draw Range Button Clicked', {
                        location: {
                          ...value,
                        },
                        tile: tile?.split('-')[0],
                      })
                    }}
                  >
                    <MarkOnMapIcon className='mark-on-the-map-icon' />
                    <p className='mark-on-the-map'>{t('generic.mark_on_map')}</p>
                  </GreenTextButton>

                )
              )
              : (
                <>
                  <GreenTextButton
                    disabled
                    onClick={drawPolygon}
                  >
                    <MarkOnMapIcon className='mark-on-the-map-icon' />
                    <p className='mark-on-the-map'>{t('generic.mark_on_map')}</p>
                  </GreenTextButton>
                  <StyledLinkWithIconDelete
                    label='generic.cancel'
                    onClick={deleteDrawPolygon}
                  />
                </>
              )}
          </>
        )}
      </SelectWrapper>
      <StyledTextButton onClick={() => downloadMap(`custom-range-bar-${customRange?.id}`)}>
        <MapIcon className='mark-on-the-map-icon' />
        <p className='mark-on-the-map'>{t('generic.download_map_png')}</p>
      </StyledTextButton>
    </Wrapper>
  )
}
