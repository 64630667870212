
import React, { useContext, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Popup from 'reactjs-popup'
import { ButtonProduct, CloseIcon } from '@dataplace.ai/ui-components/atoms'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { handleMenuModalClose } from '@dataplace.ai/functions/utils'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'apps/placeme/src/redux/hooks'
import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { ISectionTile } from '../../../slice/@types/ISectionTile'
import { ITile } from '../../../slice/@types/ITile'
import { INote } from '../../../slice/@types/INote'
import { saveNoteAction, saveNotesToDBAction, updateNoteInDBAction } from '../../../slice/analysisSlice'

const { v4: uuidv4 } = require('uuid')

const Trigger = styled.div(() => css`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`)

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem;
    min-width: 600px;
  `
})

const Header = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    > span {
      color: ${palette.black};
      font-size: ${typography.big.pt_28_semibold.fontSize};
      font-weight: ${typography.big.pt_28_semibold.fontWeight};
      line-height: ${typography.big.pt_28_semibold.lineHeight};
    }

    > button {
      cursor: pointer;
      border: none;
      outline: none;
      background: none;
    }
  `
})

const Content = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme

  return css`
  display: flex;
  flex-direction: column;

  > span:first-child {
    margin-bottom: 0.5rem;
    color: ${palette.black};
    font-size: ${typography.main.pt_15_semibold.fontSize};
    font-weight: ${typography.main.pt_15_semibold.fontWeight};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
  }

    > textarea {
      min-height: 10rem;
      outline: none;
      font-family: ${typography.primary.fontFamily};
      border: 1px solid ${palette.dark.lightest};
      border-radius: ${corners.default.borderRadius};
      padding: 1rem;
    }

    > span:last-child {
      margin-left: auto;
      margin-top: 0.25rem;
      color: ${palette.dark.normal};
      font-size: ${typography.tiny.pt_12_medium.fontSize};
      font-weight: ${typography.tiny.pt_12_medium.fontWeight};
      line-height: ${typography.tiny.pt_12_medium.lineHeight};
    }
`
})

const Footer = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
  display: flex;
  flex-direction: column;

  > span:first-child {
    margin-bottom: 0.5rem;
    width: 70%;
    color: ${palette.black};
    font-size: ${typography.tiny.pt_12_medium.fontSize};
    font-weight: ${typography.tiny.pt_12_medium.fontWeight};
    line-height: ${typography.tiny.pt_12_medium.lineHeight};
  }

    > div {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;

      > button {
        margin-left: 0.5rem;
      }

      > button:first-child {
        margin-left: 0;
        background-color: transparent;
        color: ${palette.blue}
      }
    }
`
})

export interface INotePopupProps {
  note?: INote;
  trigger: JSX.Element;
  maxLength?: number;
  close: () => void;
  sectionTile: ISectionTile;
  tile: ITile;
  editing: boolean;
  dataLoaded: boolean;
}

export const NotePopup = ({
  trigger, maxLength, close, sectionTile, tile, note, editing, dataLoaded,
}: INotePopupProps): JSX.Element => {
  const { t } = useTranslation()
  const [text, setText] = useState<string>(note?.text || '')
  const dispatch = useAppDispatch()
  const authContext = useContext(AuthContext)
  const [token, setToken] = useState('')
  const { analyseId } = useAppSelector(state => state.location)
  const { analytics } = useContext(AnalyticsContext)

  useEffect(() => {
    authContext.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value)
  }

  const handleClose = (close: (() => void)) => {
    setText(note?.text || '')
    close()
  }

  const handleSave = (close: (() => void)) => {
    const updatedNote: INote = {
      id: note?.id || uuidv4(),
      text,
      author: note?.author || authContext.userData.user?.displayName || authContext.userData.user?.email || 'Gal Anonim',
      creationDate: new Date(),
      tileType: tile?.id.split('-')[0],
      reportId: analyseId,
      catchmentId: tile?.chosenRange?.catchmentId,
      userId: authContext?.userData?.user?.uid || '',
    }

    dispatch(saveNoteAction(sectionTile.id, tile.id, updatedNote))
    analytics?.track('Note Added', {
      tile: tile?.id?.split('-')[0],
    })
    if (dataLoaded && token) {
      if (editing) {
        dispatch(updateNoteInDBAction(token, updatedNote))
      } else {
        dispatch(saveNotesToDBAction(token, [updatedNote]))
      }
    }
    setText(text || '')
    close()
  }

  return (
    <Popup
      modal
      onClose={close}
      onOpen={handleMenuModalClose}
      overlayStyle={defaultTheme.overlay}
      trigger={<Trigger>{trigger}</Trigger>}
    >
      <>
        {(close: () => void) =>
          (
            <Wrapper>
              <Header>
                <span>
                  {t('placeme.note_popup.title')}
                </span>
                <button
                  onClick={() => handleClose(close)}
                  type='button'
                >
                  <CloseIcon />
                </button>
              </Header>
              <Content>
                <span>{t('placeme.note_popup.subtitle')}</span>
                <textarea
                  maxLength={maxLength}
                  onChange={handleChange}
                  value={text}
                />
                {maxLength && <span>{`${text.length}/${maxLength} ${t('placeme.note_popup.characters')}`}</span>}
              </Content>
              <Footer>
                <span>{t('placeme.note_popup.visibility')}</span>
                <div>
                  <ButtonProduct
                    onClick={() => handleClose(close)}
                    small
                  >
                    {t('generic.cancel')}
                  </ButtonProduct>
                  <ButtonProduct
                    onClick={() => handleSave(close)}
                    small
                  >
                    {t('generic.save')}
                  </ButtonProduct>
                </div>
              </Footer>
            </Wrapper>
          )}
      </>
    </Popup>
  )
}

NotePopup.defaultProps = {
  editing: false,
}
