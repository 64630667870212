import { configureStore } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import SignUpReducer from '../features/SignUp/signUpSlice'
import SignInReducer from '../features/SignIn/signInSlice'

export const store = configureStore({
  // eslint-disable-next-line object-curly-newline
  reducer: {
    signUp: SignUpReducer,
    signIn: SignInReducer,
  },
  middleware: (getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false,
  })),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
