export const ENDPOINTS = {
  // tiles endpoints
  PEOPLE_TILE: 'data/population',
  DEMOGRAPHY_TILE: 'data/demography',
  PURCHASING_POWER_TILE: 'data/purchasing_power',
  SPENDING_TILE: 'data/spending',
  MUNICIPALITY_POPULATION_TILE: 'data/municipality_population',
  CANNIBALIZATION_TILE: 'data/cannibalization',
  TRANSPORT_TILE: 'data/transport',
  TRANSPORT_TILE_EXAMPLE_FILE: 'data/transport/example_file',
  POIS_TILE: 'data/pois',
  PARKING_TILE: 'data/parking',
  POTENTIAL_MODEL_TILE: 'data/potential_model',
  GRAVITY_MODEL_TILE: 'data/gravity_model',
  LOCAL_MARKET_SHARE_TILE: 'data/local_market_share',
  ACCESS_RANGE_TILE: 'data/access_range',
  TRAFFIC_VISUALISATION_TILE: 'data/traffic_visualisation',
  TRAFFIC_VISUALISATION_MAP_TILE: 'data/traffic_visualisation_map',
  CUSTOMER_ORIGIN_TILE: 'data/customer_origin',
  BUILDINGS_TILE: 'data/buildings',
  INVESTMENTS_TILE: 'data/investments',
  VISITING_HOURS_TILE: 'data/visiting_hours',
  CITIES_POPULATION_TILE: 'data/cities_population',
  POPULATION_HEATMAP_TILE: 'data/population_heatmap',
  LAND_USE_PLAN_TILE: 'data/land_use_plan/land_use_plan',
  SATELLITE_TILE: 'data/satellite',
  EMPLOYEES_IN_RANGE_TILE: 'data/employees_in_range',
  COMPARED_LOCATION_DATA: 'data/comparison',
  // analyses endpoints
  CREATE_DELETE_SHARE_ANALYSE: 'project',
  CREATE_ADD_DELETE_FOLDER: 'folder',
  FETCH_ANALYSES_AND_REPORTS: 'workspace/<workspace_id>/folders',
  FETCH_CATCHMENT: '/data/catchment',
  FETCH_CATCHMENT_PREVIEW: 'data/catchment/preview',
  CRUD_NOTE: 'note',
  DELETE_RESULT: 'project/<report_id>/results',
  TILES: 'tiles',
  // workspace endpoints
  PERMISSION: 'permission',
  USER: 'user',
  USER_FEATURE_MODAL_COUNTER_PATCH: 'user/counter',
  WORKSPACE: 'workspace',
  INVITATION: 'invitation',
  SUBSCRIPTION: 'subscription',
  // Big Ben endpoints
  POPULATION_BIG_BEN_TILE: 'bigben/population',
  TRAFFIC_BIG_BEN_TILE: 'bigben/traffic',
  POIS_BIG_BEN_TILE: 'bigben/pois',
  LOOK_ALIKE_BIG_BEN_TILE: 'bigben/lookalike',
  SCORING_MODEL_BIG_BEN_TILE: '/bigben/scoring_model',
  CHAIN_RANKING_BIG_BEN_TILE: 'bigben/ranking',
  // PDF
  PDF: 'data/report_pdf',
  PDF_LINK: 'project/pdf',
  // templates
  TEMPLATES: 'templates',
  // other
  REPORT_SUGGESTION: 'tiles/suggestions',
}
