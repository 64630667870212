import { createSlice } from '@reduxjs/toolkit'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { createNavbarNotification, navbarNotificationsEventName } from '@dataplace.ai/functions/utils/createNavbarNotification'
import { getI18n } from 'react-i18next'
import dayjs from 'dayjs'
import { placemePlans } from '@dataplace.ai/constants'
import { ISubscription } from '@dataplace.ai/types'
import type { AppThunk } from '../../redux/store'
import { ENDPOINTS } from '../../constants/endpoints'
import { config } from '../../config'
import { IChooseLocationSlice } from './@types/IChooseLocationSlice'

const initialState: IChooseLocationSlice = {
  workspaces:{
    loading: true,
    error: null,
  },
  currentWorkspaceData: {
    loading: true,
    error: null,
  },
  currentSubscriptionData: {
    loading: true,
    error: null,
  },
}

const chooseLocationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    saveUsersWorkspaces : (state, action) => {
      state.workspaces = action.payload
    },
    saveCurrentWorkspaceData : (state, action) => {
      state.currentWorkspaceData = action.payload
    },
    saveCurrentSubscriptionData : (state, action) => {
      state.currentSubscriptionData = action.payload
    },
    addLocation: (state, action) => {
      state.value = action.payload
    },
    saveWorkspaceId : (state, action) => {
      state.workspaceId = action.payload
    },
    addFlyToLocation: (state, action) => {
      state.flyToLocation = action.payload
    },
    saveAnalyseId : (state, action) => {
      state.analyseId = action.payload
    },
    saveComparedAnalyseId : (state, action) => {
      state.comparedAnalyseId = action.payload
    },
    resetLocationState: () => initialState,
    saveUserType : (state, action) => {
      state.userType = action.payload
    },
    saveUserInfo : (state, action) => {
      state.userInfo = action.payload
    },
  },
})

export const fetchUsersWorkspaces = (idToken: string): AppThunk => async (dispatch): Promise<void> => {
  let data

  try {
    const response = await getAxios(config.API_URL, idToken).get(`${ENDPOINTS.PERMISSION}`)
    data = {
      loading: false,
      error: response.statusText,
      value: response.data,
    }
  } catch (e) {
    data = {
      loading: false,
      error: e.error,
      value: null,
    }
  }
  dispatch(saveUsersWorkspaces(data))
  window.dispatchEvent(new CustomEvent(navbarNotificationsEventName))
  if (data?.value?.subscriptionId) {
    dispatch(fetchSubscriptionInfo(idToken, data.value.subscriptionId))
  }
}

// eslint-disable-next-line max-len
export const fetchCurrentWorkspaceData = (idToken: string, workspaceId: string, withPlans?: boolean): AppThunk => async (dispatch): Promise<void> => {
  let data

  try {
    const response = await getAxios(config.API_URL, idToken).get(`${ENDPOINTS.WORKSPACE}/${workspaceId}`)
    data = {
      loading: false,
      error: response.statusText,
      value: response.data,
    }
  } catch (e) {
    data = {
      loading: false,
      error: e.error,
      value: null,
    }
  }
  dispatch(saveCurrentWorkspaceData(data))
  if (data?.value) {
    dispatch(fetchSubscriptionInfo(idToken, data?.value?.subscriptionId, withPlans))
  }
}

export const createNewAnalyse = (
  token: string, mainReport?: string, comparedLocation?: {address: string, lat: number, lng: number},
): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const {
      address, lat, lng,
    } = getState().location?.value as {address: string, lat: number, lng: number}

    // mainReport - main analyse id -> needed for creating  compared location analyse
    const body = mainReport && comparedLocation
      ? {
        lat: comparedLocation?.lat,
        lng: comparedLocation?.lng,
        address: comparedLocation?.address,
        visibility: false,
        mainReport,
      }
      : {
        lat,
        lng,
        address,
        visibility: false,
      }

    let data

    try {
      const response = await getAxios(config.API_URL, token).post(ENDPOINTS.CREATE_DELETE_SHARE_ANALYSE || '', body)
      data = {
        loading: false,
        error: '',
        value: response.data,
      }
    } catch (e) {
      data = {
        loading: false,
        error: e.error,
        value: null,
      }
    }
    if (!mainReport) {
      dispatch(saveAnalyseId(data?.value?.id))
      window.localStorage.removeItem('comparedLocation')
      window.localStorage.removeItem('comparedAnalyseId')
    } else {
      dispatch(saveComparedAnalyseId(data?.value?.id))
      window.localStorage.setItem('comparedAnalyseId', data?.value?.id)
      window.dispatchEvent(new CustomEvent('comparedAnalyseId'))
      return data?.value?.id
    }
  }

export const fetchUserInfo = (token: string, userId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    let saveData
    try {
      const response = await getAxios(config.API_URL, token).get(`${ENDPOINTS.USER}/${userId}`)
      saveData = {
        loading: false,
        error: '',
        value: response.data,
      }
    } catch (e) {
      // eslint-disable-next-line no-unused-vars
      saveData = {
        loading: false,
        error: e.error,
        value: null,
      }
    }
    finally {
      if (saveData?.value) {
        dispatch(saveUserInfo(saveData))
      }
    }
  }

export const fetchSubscriptionInfo = (token: string, subscriptionId: string | null, withPlans?: boolean): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    let data: { value: null | ISubscription; loading?: boolean; error?: string }

    const { currentWorkspaceData } = getState().location
    const workspaceCutOffDate = '2022-03-10T14:00:00.243554'

    if (subscriptionId !== null) {
      try {
        const response = await getAxios(config.API_URL, token).get(`${ENDPOINTS.SUBSCRIPTION}/${subscriptionId}${withPlans ? '?withPlans=true' : ''}`)
        data = {
          loading: false,
          error: '',
          value: response.data,
        }
      } catch (e) {
        data = {
          loading: false,
          error: e.error,
          value: null,
        }
      }

      if (data?.value) {
        dispatch(saveCurrentSubscriptionData({
          loading: false,
          error: null,
          value: {
            ...data?.value,
            plan: data?.value?.plan === null ? 'white' : data?.value?.plan,
            planExact: data?.value?.planExact === null ? 'white' : data?.value?.planExact,
            status: data?.value?.status,
          },
        }))
        // trial
        const expireDate = new Date(data?.value?.cancelAt as string)
        expireDate.setHours(0, 0, 0, 0)

        const today = new Date()
        today.setHours(0, 0, 0, 0)
        today.setDate(today.getDate() + 7)

        const createdWhite = new Date(data?.value?.created as string)
        createdWhite.setHours(0, 0, 0, 0)

        // white started
        const todayWhite = new Date()
        todayWhite.setHours(0, 0, 0, 0)

        // next payment day
        const nextPaymentDay = new Date(data?.value?.currentPeriodEnd as string)
        nextPaymentDay.setHours(0, 0, 0, 0)
        nextPaymentDay.setDate(nextPaymentDay.getDate() - 2)

        const todayDay = new Date()
        todayDay.setHours(0, 0, 0, 0)

        // if (data?.value?.plan !== placemePlans.trial && data?.value?.plan !== placemePlans.white) {
        //   if (todayDay >= nextPaymentDay) {
        //     const i18n = getI18n()
        //     createNavbarNotification({
        //       id: 'placeme_payment_soon',
        //       text: `${i18n.t('placeme.notification.payment_soon.text1')}`,
        //       subText:
        //        `${i18n.t('placeme.notification.payment_soon.sub_text_1')}
        // (${dayjs(data?.value?.currentPeriodEnd).format('DD.MM.YYYY')}),
        //  ${i18n.t('placeme.notification.payment_soon.sub_text_2')}`,
        //       showCloseButton: true,
        //       translate: false,
        //       showContactButton: true,
        //     })
        //   }
        // }

        if (data?.value?.plan === placemePlans.trial) {
          const workspaceCreationDate = new Date(currentWorkspaceData?.value?.creationDate as string) // workspace creation date
          workspaceCreationDate.setHours(0, 0, 0, 0)
          // cut off date for new workspaces
          const cutOffDate = new Date(workspaceCutOffDate)
          cutOffDate.setHours(0, 0, 0, 0)

          if (today >= expireDate && workspaceCreationDate < cutOffDate) {
            const i18n = getI18n()
            createNavbarNotification({
              id: 'placeme_trial_ending',
              text: `${i18n.t('placeme.trial_ending.navbar_notification.main_text_1')} (${data?.value?.credits}) ${i18n.t('generic.trial_ending.navbar_notification.main_text_2')}`,
              subText: `${i18n.t('placeme.trial_ending.navbar_notification.sub_text')} ${dayjs(expireDate).format('DD.MM.YYYY')}`,
              showCloseButton: true,
              translate: false,
            })
          }

          if (data?.value?.credits <= 4 && workspaceCreationDate < cutOffDate) {
            const i18n = getI18n()
            createNavbarNotification({
              id: 'placeme_trial_tiles_ending',
              text: `${i18n.t('placeme.trial_ending.navbar_notification.4_left_1')} (${data?.value?.credits}) ${i18n.t('placeme.trial_ending.navbar_notification.4_left_2')}`,
              subText: `${i18n.t('placeme.trial_ending.navbar_notification.sub_text.4_left')}`,
              showCloseButton: true,
              translate: false,
            })
          }
        }

        if (todayWhite >= createdWhite && data?.value?.plan === null) {
          const i18n = getI18n()
          createNavbarNotification({
            id: 'placeme_white_started',
            text: `${i18n.t('placeme.navbar_notification.white_started')}`,
            subText: `${i18n.t('placeme.navbar_notification.white_started_2')}`,
            showCloseButton: true,
            translate: false,
          })
        }
      }
    }

    else {
      dispatch(saveCurrentSubscriptionData({
        loading: false,
        error: null,
        value: {
          plan: 'white',
          planExact:'white',
          credits: 'unlimited',
          subscriptionId: null,
        },
      }))
      if (window?.localStorage?.getItem('whiteDeleted') !== 'true') {
        const i18n = getI18n()

        // trial ended
        const workspaceCreationDate = new Date(currentWorkspaceData?.value?.creationDate as string) // workspace creation date
        workspaceCreationDate.setHours(0, 0, 0, 0)

        // cut off date for new workspaces
        const cutOffDate = new Date(workspaceCutOffDate)
        cutOffDate.setHours(0, 0, 0, 0)

        if (workspaceCreationDate >= cutOffDate) {
          const i18n = getI18n()
          createNavbarNotification({
            id: 'placeme_trial_ended',
            text: `${i18n.t('placeme.navbar_notification.white_started')}`,
            subText: `${i18n.t('placeme.navbar_notification.trial_ended.sub')}`,
            xButton: true,
            translate: false,
            showContactButton: false,
            showArrangeMeetingButton: true,
          })
        }
        if (workspaceCreationDate < cutOffDate) {
          createNavbarNotification({
            id: 'placeme_white_started',
            text: `${i18n.t('placeme.navbar_notification.white_started')}`,
            subText: `${i18n.t('placeme.navbar_notification.white_started_2')}`,
            showCloseButton: true,
            translate: false,
          }) }
      }
    }
  }

export const {
  addLocation, saveAnalyseId, saveWorkspaceId, addFlyToLocation, saveUsersWorkspaces, resetLocationState, saveUserType,
  saveCurrentWorkspaceData, saveCurrentSubscriptionData, saveComparedAnalyseId, saveUserInfo,
} = chooseLocationSlice.actions

export default chooseLocationSlice.reducer
