/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  MapContainerProps,
  useMap,
  ScaleControl,
} from 'react-leaflet'
import L from 'leaflet'
import 'leaflet-contextmenu'
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css'
import { MainDropdown } from '@dataplace.ai/ui-components/molecules'
import { DrawRangeTool, MainLayersBar } from '@dataplace.ai/ui-components/atoms'
import { ILayer } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/ILayer'
import { IRange, ILocation } from '@dataplace.ai/types'
import { useTranslation } from 'react-i18next'
import { AnalyticsContext } from '@dataplace.ai/features'
import { MapOverlays } from './components/MapOverlays/MapOverlays'
import { IGeojson } from './components/MapOverlays/@types/IGeojson'
import { IWMSLayer } from './components/MapOverlays/@types/IWMSLayer'
import { IMapTile } from './@types/IMapTile'
import { IFeatureCollection } from './components/MapOverlays/@types/IFeatureCollection'
import { CurrentMapState } from './components/CurrentMapState'

interface StyledMapContainerProps extends MapContainerProps {
  width?: string
  height?: string
  contextmenu: boolean
  contextmenuItems: {
    text: string
    callback: (e: unknown) => void
  }[]
}
interface ICustomMapContainer extends MapContainerProps {
  contextmenu: boolean
}
export declare function CustomMapContainer({
  children,
  className,
  id,
  placeholder,
  style,
  ...options
}: ICustomMapContainer): JSX.Element

const StyledMapContainer = styled(MapContainer)<StyledMapContainerProps>(({
  height, width,
}) => css`
  width: ${width || 'auto'};
  height: ${height || '150px'};
  border-radius: 8px;

  .leaflet-control-container {
    position: absolute;
    bottom: 105px;
    left: 15px;
    z-index: 600;

    .leaflet-control-scale{
      position: absolute;
      top: 45px; 
      left: 60px;
      
      .leaflet-control-scale-line{
        border-width: 1px;
        background:rgba(255, 255, 255, 0.83);
        color: #07216c;
        :not(first-child){
          border-top: none;
        }
      }
    }

    .sr-only {
    display: none;
  }
  .leaflet-draw-section{
    display: none;
  }
 
    .leaflet-bar {
      border: none;
    }

    .leaflet-bar {
      
      a {
        border: none;
        width: 40px;
        height: 36px;
        line-height: 35px;
        color: #234596;
      }

      a:first-child {
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      }

      a:last-child {
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
      }
    }
  }
`)
const PopupHeading = styled.h4`
  margin: 0;
  font-weight: 600;
`
const PopupParagraph = styled.h5`
  margin: 5px 0 0 0;
  font-size: 10px;
  color: #423ab3;
  font-weight: 600;
`

const mapStyles = [
  {
    id: 'default',
    name: 'placeme.map_classic_type',
    style: 'ck6wd9bf11dwz1io8y1fvh3os',
  },
  {
    id: 'default-no-cities',
    name: 'placeme.map_classic_no_cities_type',
    style: 'ck721i1ce0xnt1ipgqg2phlac',
  },
  {
    id: 'dark',
    name: 'placeme.map_dark_type',
    style: 'ck7q258it0o0m1ijg9g076dfw',
  },
  {
    id: 'light',
    name: 'placeme.map_light_type',
    style: 'ck7q22mog06dt1ijzpnekd4oq',
  },
  {
    id: 'satellite',
    name: 'placeme.map_satellite_type',
    style: 'ck72206dr091e1ipfj5zu6js1',
  },
]

const FlyTo = ({ location }: { location: ILocation }) => {
  const map = useMap()
  map.setView([location.lat, location.lng], map.getZoom())
  return null
}

export type IMapLocationProps = {
  zoom: number,
  bbox?: {
    northEastLat: number,
    northEastLng: number,
    southWestLat: number,
    southWestLng: number
  },
  center: {
    lat: number,
    lng: number,
  }
}

export interface IMapTileProps {
  width: string
  height: string
  scroll?: boolean
  popupHeading?: string
  popupParagraph?: string
  location?: { address: string; lat: number; lng: number }
  pinDisplayed: boolean
  mapTypeDropdown?: boolean
  dragging?: boolean
  mapId: string
  zoomControl?: boolean
  editRef?: React.MutableRefObject<null>
  layersBar?: boolean
  layers?:
  | {
    id: string
    layer: ILayer
  }[]
  | {
    id: string
    layer: IGeojson
  }[]
  handleDraw?: (drawRange: IRange) => void
}

export type StyleProps = {
  id: string
  name: string
  style: string
}

export function MapTile({
  style,
  styleLayer,
  height,
  width,
  scroll,
  popupHeading,
  popupParagraph,
  location,
  flyToLocation,
  pinDisplayed,
  mapTypeDropdown,
  mapTypeDropdownStyle,
  dragging,
  mapId,
  zoomControl,
  handleDraw,
  layersBar,
  layers,
  onLoad,
  zoom,
  removingDrawRangeLayers,
  setDrawRangeChoose,
  handleLocationChoose,
  maxRange,
  drawPolygon,
  setMapLocation,
  comparedLocation,
  popupHeadingCompared,
  popupParagraphCompared,
  shouldMapStyleBeSave,
  showScaleControl,
}: IMapTile): JSX.Element {
  const { t } = useTranslation()
  const { analytics } = useContext(AnalyticsContext)
  const [readyToAddLayers, setReadyToAddLayers] = useState(false)
  const [pinLocation, setPinLocation] = useState<L.LatLngExpression>([52.2296756, 21.0122287])
  const [mapStyle, setMapStyle] = useState<StyleProps>({
    id: 'light',
    name: 'placeme.map_light_type',
    style: 'ck7q22mog06dt1ijzpnekd4oq',
  })
  const [layersToDisplay, setLayersToDisplay] = useState<(ILayer | IGeojson | IWMSLayer | IFeatureCollection)[]
  >([])

  const pin = L.icon({
    iconUrl: '/assets/markers/placemeMarker.svg',
    iconSize: [32, 44],
    iconAnchor: [16, 43],
    popupAnchor: [-1, -54],
    shadowUrl: '/assets/markers/markerShadow.png',
    shadowSize: [33, 44],
    shadowAnchor: [11, 39],
  })

  const yellowPin = L.icon({
    iconUrl: '/assets/markers/yellowPin.svg',
    iconSize: [32, 44],
    iconAnchor: [16, 43],
    popupAnchor: [-1, -54],
    shadowUrl: '/assets/markers/markerShadow.png',
    shadowSize: [33, 44],
    shadowAnchor: [11, 39],
  })

  const handleMapStyle = (style: StyleProps) => {
    setMapStyle(style)
    if (window.location.pathname.includes('choose-location') || shouldMapStyleBeSave) {
      window.localStorage.setItem('mapStyle', style?.id)
      window?.dispatchEvent(new CustomEvent('mapStyle'))
    }
  }

  const handleSavedMapStyle = () => {
    const storageMapStyleId = window?.localStorage?.getItem('mapStyle')
    if (storageMapStyleId && !style) {
      const storageMapStyle = mapStyles?.find(style => style?.id === storageMapStyleId)
      if (storageMapStyle) setMapStyle(storageMapStyle)
    }
  }

  const handleSwitchMapLayer = (layersIds: string[]) => {
    const newLayers: (ILayer | IGeojson | IWMSLayer | IFeatureCollection)[] = []
    layers?.forEach(
      (
        l:
        | {
          id: string
          layer: ILayer | IGeojson | IWMSLayer | IFeatureCollection
        }
        | {
          id: string
          layer: IGeojson
        },
      ) => (layersIds.includes(l.id) ? newLayers.push(l.layer) : null),
    )
    setLayersToDisplay(newLayers)
  }
  useEffect(() => {
    if (location) setPinLocation([location.lat, location.lng])
  }, [location])

  useEffect(() => {
    if (flyToLocation) setPinLocation([flyToLocation.lat, flyToLocation.lng])
  }, [flyToLocation])

  useEffect(() => {
    if (!layersBar && readyToAddLayers) {
      const newLayers: (ILayer | IGeojson | IWMSLayer | IFeatureCollection)[] = []
      layers?.forEach(
        (l: {
          id: string
          layer: ILayer | IGeojson | IWMSLayer | IFeatureCollection
        }) => newLayers.push(l.layer),
      )
      setLayersToDisplay(newLayers)
    }
  }, [layers, readyToAddLayers])

  useEffect(() => {
    if (style) setMapStyle(style)
  }, [style])

  useEffect(() => {
    if (!style) handleSavedMapStyle()
  }, [style])

  // set saved map style
  useEffect(() => {
    // detect updating saved map style
    window.addEventListener('mapStyle', handleSavedMapStyle)
    // remove listener
    return () => window.removeEventListener('mapStyle', handleSavedMapStyle)
  }, [style])

  return (
    <StyledMapContainer
      attributionControl={false}
      center={
        location ? [location.lat, location.lng] : [52.2296756, 21.0122287]
      }
      contextmenu={!!handleLocationChoose}
      contextmenuItems={[
        {
          text: t('placeme.map.analyse_location'),
          callback: (e) => {
            const data = e as { latlng: { lat: number; lng: number } }
            if (handleLocationChoose) {
              handleLocationChoose(data.latlng.lat, data.latlng.lng)
              analytics?.track('Analyse Button Clicked (RMB)', {
                location: {
                  lat: data.latlng.lat,
                  lng: data.latlng.lng,
                },
              })
            }
          },
        },
      ]}
      dragging={dragging}
      height={height}
      id={mapId}
      maxZoom={17}
      minZoom={8.25}
      scrollWheelZoom={scroll}
      wheelPxPerZoomLevel={90}
      whenReady={() => {
        if (onLoad) {
          onLoad()
        }

        setReadyToAddLayers(true)
      }}
      width={width}
      zoom={zoom || 13}
      zoomControl={!!zoomControl}
      zoomDelta={0.75}
      zoomSnap={0}
    >
      {showScaleControl && <ScaleControl />}
      {!!setMapLocation
      && (
        <CurrentMapState
          setMapLocation={setMapLocation}
        />
      ) }
      {layersToDisplay && (
        <MapOverlays
          layers={layersToDisplay}
        />
      )}
      {flyToLocation && (
        <FlyTo
          location={flyToLocation}
        />
      )}

      {pinDisplayed && (
        <Marker
          icon={pin}
          position={
            location
              ? [location.lat, location.lng]
              : (flyToLocation
                ? [flyToLocation?.lat, flyToLocation?.lng]
                : [52.2296756, 21.0122287])
          }
        >
          <Popup>
            <PopupHeading>{popupHeading}</PopupHeading>
            <PopupParagraph>{popupParagraph}</PopupParagraph>
          </Popup>
        </Marker>
      )}

      {comparedLocation
       && (
         <Marker
           icon={yellowPin}
           position={[comparedLocation?.lat, comparedLocation?.lng]}
         >
           <Popup>
             <PopupHeading>{popupHeadingCompared}</PopupHeading>
             <PopupParagraph>{popupParagraphCompared}</PopupParagraph>
           </Popup>
         </Marker>
       )}

      {mapStyles.map(
        (m) =>
          mapStyle.id === m.id && (
            <TileLayer
              key={m.id}
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url={`https://api.mapbox.com/styles/v1/${styleLayer?.user || process.env.NX_MAPBOX_USER}/${styleLayer?.styleId || m.style}/tiles/512/{z}/{x}/{y}@2x?access_token=${process.env.NX_MAPBOX_ACCESS_TOKEN}`}
            />
          ),
      )}

      {handleDraw && location && drawPolygon && (
        <DrawRangeTool
          drawPolygon={drawPolygon}
          handleDraw={handleDraw}
          location={location}
          maxRange={maxRange}
          removingDrawRangeLayers={removingDrawRangeLayers || false}
          setDrawRangeChoose={setDrawRangeChoose}
        />
      )}
      {pinDisplayed && (
        <Marker
          icon={pin}
          position={pinLocation}
        >
          <Popup>
            <PopupHeading>{popupHeading}</PopupHeading>
            <PopupParagraph>{popupParagraph}</PopupParagraph>
          </Popup>
        </Marker>
      )}

      {mapTypeDropdown && (
        <MainDropdown
          content={(
            <>
              {mapStyles.map((s) => (
                <button
                  key={s.id}
                  onClick={() => handleMapStyle(s)}
                  type='button'
                >
                  {t(s.name)}
                </button>
              ))}
            </>
          )}
          header={(
            <p>
              {t('placeme.map_type')}
              :
              {mapStyle?.name && <span>{t(mapStyle?.name)}</span>}
            </p>
          )}
          style={mapTypeDropdownStyle
            || {
              position: 'absolute',
              zIndex: 600,
              top: '25px',
              left: '15px',
            }}
        />
      )}
      {layersBar && (
        <MainLayersBar
          handleSwitch={handleSwitchMapLayer}
          style={{
            position: 'absolute',
            zIndex: 600,
            top: '5px',
            left: '320px',
          }}
        />
      )}
    </StyledMapContainer>
  )
}

export default MapTile

