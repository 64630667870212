import React from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { IButtonProps } from './@types/IButton.props'

// TODO other styles

const StyledButton = styled.button<IButtonProps>(({
  theme, small, margin, deleteButton, maxWidth,
}) => {
  const {
    palette, corners, typography, shadows,
  } = theme
  return css`
    margin: ${margin || '0'};
    background: ${deleteButton ? palette.results.red.light : palette.blue};
    border-radius: ${corners.default.borderRadius};
    outline: none;
    border: 1px solid ${deleteButton ? palette.results.red.dark : palette.dark.main};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${small ? '5px 15px' : '10px 20px'};
    min-width: ${maxWidth || (small ? '119px' : '180px')};
    max-height: 40px;
    color: ${palette.light.white};
    font-size: ${typography.small.pt_13_medium.fontSize};
    font-weight: ${typography.small.pt_13_medium.fontWeight};
    line-height: ${typography.small.pt_13_medium.lineHeight};

    transition: all .2s ease-in-out;

    :hover {
      background: ${deleteButton ? palette.light.darker : palette.product.location.main};
      box-shadow: ${shadows.hover.boxShadow};
      cursor: pointer;
      color: ${deleteButton ? palette.results.red.dark : 'white'};
      border-color: ${deleteButton ? palette.light.darker : palette.dark.main}
    }

    :focus{
      background: ${deleteButton ? palette.results.red.light : palette.product.location.light};
    }

    :active {
      border: 1px solid #E1E2E3;;
      background-color:${palette.light.main};
      color:${palette.blue};
    }

    :disabled{
      background: ${palette.dark.lightest};
      border: none;
    }

  `
})

export const ButtonProduct: React.FC<IButtonProps> = ({
  children, onClick, as, to, small, margin, maxWidth, ...restProps
}) => (
  <>
    {as === 'navLink' && to
      ? (
        <NavLink to={to}>
          <StyledButton
            {...restProps}
            margin={margin}
            maxWidth={maxWidth}
            onClick={onClick}
            small={small}
          >
            {children}
          </StyledButton>
        </NavLink>
      )
      : (
        <StyledButton
          margin={margin}
          maxWidth={maxWidth}
          onClick={onClick}
          small={small}
          {...restProps}
        >
          {children}
        </StyledButton>
      )}
  </>
)
