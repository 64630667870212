
import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import {
  LinkWithIconCheckOnMap,
  TitleFormSectionSubTitle,
  TileFormWrapper,
  MapTile,
  IMapLocationProps,
} from '@dataplace.ai/ui-components/atoms'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '@dataplace.ai/features'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { BasicLayers, FeatureCollectionLayers, GeojsonLayers, WMSLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import { RootState } from '../../../../../../../redux/store'
import { fetchWorkspaceUsageValue, saveTileData } from '../../../../../slice/analysisSlice'
import { IPopulationHeatmap } from './@types/IPopulationHeatmap'
import { ComparedLocationHeader } from '../../../../atoms'

const MapWrapper = styled.div(
  () => css`
    margin: 1rem 0;
    width: 100%;
    height: 300px;
  `,
)

export const PopulationHeatmapTile: React.FC<{
  data: IPopulationHeatmap, tileId: string}> = ({
  data, tileId,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [token, setToken] = useState('')
  const { value } = useSelector((state: RootState) => state.location)
  const {
    values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const authContext = useContext(AuthContext)
  useEffect(() => {
    if (token.length && !data?.mapLocation) {
      dispatch(fetchWorkspaceUsageValue(token))
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData?.user?.getIdToken(true)?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [isComparedMapDisplayed, setIsComparedMapDisplayed] = useState(false)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 15,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const [comparedMapLocation, setComparedMapLocation] = useState<IMapLocationProps>({
    zoom: 15,
    center: {
      lat: comparedLocation?.location?.lat || 0,
      lng: comparedLocation?.location?.lng || 0,
    },
  })

  const handleMapOpen = (compared: boolean) => {
    if (compared) { setIsComparedMapDisplayed(!isComparedMapDisplayed) }
    else { setIsMapDisplayed(!isMapDisplayed) }
  }

  const getLayers = (compared: boolean) => {
    const tile = values?.find(c => c.id === 'visualization')?.tiles?.find(t =>
      t.id === tileId)
    const heatMap = compared ? data?.value?.comparedLocation?.populationHeatmap : data?.value?.populationHeatmap
    const rangeCoords = compared
      ? tile?.comparedChosenRange?.geoJSON?.coordinates
      : tile?.chosenRange?.geoJSON?.coordinates
    if (heatMap) {
      const layers: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[] = [
        // heat map layer
        {
          id: heatMap?.id,
          layer: {
            ...heatMap,
            options: {
              type: heatMap?.type,
              id: heatMap?.id,
              style: {
                ...heatMap?.style,
                blur: 5,
                radius: 90,
                minOpacity: 0.07,
                gradient: {
                  1:'#FF0000',
                  '0.0':'#0099FF',
                  0.3:'#FFFF99',
                  0.65:'#FF9933',
                },
              },

            },
          },
        },
        // ranger layer
      ]

      if (rangeCoords) {
        layers.push({
          id: (compared ? `${tile?.id}-compared` : tile?.id) || '',
          layer: {
            data: {
              coordinates: (rangeCoords) as IGeojson['data']['coordinates'],
              type: 'Polygon',
              properties: {},
            },
            options:{
              type: 'geojson',
              id: compared ? 'people_compared' : 'people',
              style: {
                color: '#0000a2',
                fillColor:'#0000a2',
                weight: 1.5,
                fillOpacity: 0.05,
              },
            },
          },
        })
      }

      return layers
    }
    return undefined
  }

  useEffect(() => {
    if (data?.value?.populationHeatmap && mapLocation) {
      dispatch(saveTileData('visualization', tileId, {
        ...data,
        mapLocation,
        comparedMapLocation: data?.value?.comparedLocation ? comparedMapLocation : undefined,
      }))
    }
  }, [mapLocation, comparedMapLocation])

  return !data || data?.loading
    ? (<Loader />)
    : (
      data?.value?.populationHeatmap
        ? (
          <>
            <TileFormWrapper>
              <>
                {data?.value?.comparedLocation
                 && (
                   <ComparedLocationHeader>
                     <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
                     {' '}
                     <span>{value?.address}</span>
                   </ComparedLocationHeader>
                 )}
                <TitleFormSectionSubTitle>
                  <span>{t('placeme.people_tile.section_sub_title_2_span_1')}</span>
                  <LinkWithIconCheckOnMap onClick={() => handleMapOpen(false)} />
                </TitleFormSectionSubTitle>
                <MapWrapper>
                  <MapTile
                    height='100%'
                    layers={getLayers(false)}
                    location={value}
                    mapId='example-map-data-tile'
                    pinDisplayed
                    popupHeading={t('generic.chosen_location')}
                    popupParagraph={value?.address}
                    setMapLocation={setMapLocation}
                    showScaleControl
                    width='100%'
                    zoom={15}
                    zoomControl
                  />
                </MapWrapper>
              </>
              {/* compared location */}
              {data?.value?.comparedLocation
               && (
                 <>
                   <ComparedLocationHeader
                     second
                     style={{
                       paddingTop: '2rem',
                     }}
                   >
                     <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
                     {' '}
                     <span>{comparedLocation?.location?.address}</span>
                   </ComparedLocationHeader>
                   <TitleFormSectionSubTitle>
                     <span>{t('placeme.people_tile.section_sub_title_2_span_1')}</span>
                     <LinkWithIconCheckOnMap onClick={() => handleMapOpen(true)} />
                   </TitleFormSectionSubTitle>
                   <MapWrapper>
                     <MapTile
                       height='100%'
                       layers={getLayers(true)}
                       location={comparedLocation?.location}
                       mapId='example-map-data-tile-compared'
                       pinDisplayed
                       popupHeading={t('generic.chosen_location')}
                       popupParagraph={comparedLocation?.location?.address}
                       setMapLocation={setComparedMapLocation}
                       showScaleControl
                       width='100%'
                       zoom={15}
                       zoomControl
                     />
                   </MapWrapper>
                 </>
               )}
            </TileFormWrapper>

            {isMapDisplayed && (
              <MapVisualizationModal
                isDisplayed={isMapDisplayed}
                layers={getLayers(false)}
                location={value}
                mapId={`people-tile-${values?.find(c => c.id === 'population')?.tiles?.find(t => t.id === 'people')?.chosenRange?.catchmentId}`}
                setIsDisplay={setIsMapDisplayed}
                zoom={15}
              />
            )}

            {/* compared location */}
            {isComparedMapDisplayed && (
              <MapVisualizationModal
                isDisplayed={isComparedMapDisplayed}
                layers={getLayers(true)}
                location={comparedLocation?.location}
                mapId={`people-tile-${values?.find(c => c.id === 'population')?.tiles?.find(t => t.id === 'people')?.chosenRange?.catchmentId}_compared`}
                setIsDisplay={setIsComparedMapDisplayed}
                zoom={15}
              />
            )}
          </>
        )
        : null

    ) }
