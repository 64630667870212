import React, { SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features'
import { useTranslation } from 'react-i18next'
import { NumberInput } from '@dataplace.ai/ui-components/molecules'
import { getRadarChartData, getRadarChartLabels, RadarChart } from '@dataplace.ai/ui-components/atoms'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { getAxios } from '@dataplace.ai/functions/utils'
import { ITileData } from 'apps/placeme/src/features/Analyse/slice/@types/ITileData'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { config } from 'apps/placeme/src/config'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { deleteTileAction, fetchWorkspaceUsageValue, saveNewRangeAction, saveTileData } from '../../../../../slice/analysisSlice'
import { DataType, IPotentialTileData, ModelParams } from './@types/IPotentialTileData'
import { TileFooter } from '../../../../atoms'
import { ManagePotentialModelData, ManagePotentialModelParams } from '../../../../molecules/ManagePotentialModelParams'
import { SettingsBox } from '../../../../molecules/SettingsBox'
import { labels } from './data'
import { SalesPotentialLabels } from '../../Potential/SalesPotential/data'

const Wrapper = styled.div<{ background: 'light' | 'dark' }>(({
  theme, background,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${background === 'light'
    ? palette.light.white
    : palette.light.main};
    > p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;
      text-transform: uppercase;
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    }
  `
})

const Title = styled.h4(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    font-weight: 500;
    font-size: ${typography.main.pt_15_semibold.fontSize};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
    letter-spacing: ${typography.main.pt_15_semibold.letterSpacing};
    color: ${palette.black};
  `
})

const ChartTitle = styled.h5(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    font-weight: 500;
    font-size: ${typography.main.pt_15_semibold_upper.fontSize};
    line-height: ${typography.main.pt_15_semibold_upper.lineHeight};
    letter-spacing: ${typography.main.pt_15_semibold_upper.letterSpacing};
    text-transform: ${typography.main.pt_15_semibold_upper.textTransform};
    color: ${palette.black};
  `
})

const TypeRangeWrapper = styled.div(
  ({ theme }) => {
    const {
      palette, typography, corners,
    } = theme
    return css`
    display: flex;
    flex-direction: column;
    background: ${palette.light.white};
    border: 1.5px dashed ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    padding: 1rem;
    color: ${palette.black};
    margin-top: 1.5rem;
    
    > p {
      color: ${palette.black};
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
    }
` },
)

const InputWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.dark.normal};
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
    display: inline-block;
    width: 50%;
    margin-top: 1.75rem;
    :first-of-type {
      margin-right: 1rem;
    }
` })

const Estimation = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    padding-bottom: 30px;
    margin: 30px 0;
    border-bottom: 1px solid ${palette.light.darkest};

    > p {
      font-size: ${typography.main.pt_15_semibold.fontSize};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      letter-spacing: ${typography.main.pt_15_semibold.letterSpacing};
      color: ${palette.black};
      > strong {
        font-size: 28px;
        line-height: 40px;
        margin-left: 0.5rem;
      }
    }

    > span {
      color: ${palette.black};
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
    }
` })

const StyledManagePotentialModelParams = styled(ManagePotentialModelParams)`
  margin-top: 1.75rem;
`

export const PotentialTile: React.FC<{
  data: IPotentialTileData,
  tileId: string,
  accepted: boolean,
  setAccepted: React.Dispatch<React.SetStateAction<boolean>>,
}> = ({
  data, tileId, accepted, setAccepted,
}) => {
  // variables
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const authContext = useContext(AuthContext)
  const { values } = useSelector((state: RootState) => state.analysis)
  const {
    analyseId, comparedAnalyseId,
  } = useSelector((state: RootState) => state.location)
  const relatedValues: Record<DataType, {item: DataType, step: number}> = {
    footTraffic: {
      item:'carTraffic',
      step: 5,
    },
    carTraffic: {
      item: 'footTraffic',
      step: 5,
    },
  }

  // states
  const [token, setToken] = useState('')
  const [area, setArea] = useState<string>('1000')
  const [modelParams, setModelParams] = useState<ModelParams>({})
  const [shopArea, setShopArea] = useState(0)
  const [canBeReset, setCanBeReset] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)

  // functions
  const getTileType = (id: string) => id.split('-')[0]

  const fetchTileRange = () => values.find(cat => cat.id === 'big_ben')?.tiles?.find(t => t.id === tileId)?.chosenRange?.catchmentId

  const fetchData = useCallback(async (modelParams?: ModelParams, previous?: boolean) => {
    const catchmentId = values.find(cat => cat.id === 'big_ben')?.tiles?.find(t => t.id === tileId)?.chosenRange?.catchmentId

    if (accepted) {
      let body
      if (modelParams) {
        body = {
          catchmentId,
          shop_area: shopArea?.toString(),
          data: mapModelParamsData(modelParams),
        }
      } else if (previous) {
        body = {
          catchmentId,
          previous_data: previous,
        }
      } else {
        body = {
          catchmentId,
          shop_area: area,
        }
      }

      const endpoint = Object.entries(ENDPOINTS).find((key) => key[0] === `${getTileType(tileId).toUpperCase()}_TILE`)?.[1]
      let saveData

      try {
        const response = await getAxios(config.API_URL, token).post<ITileData>(endpoint || '', body)
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
        if (response.status === 204) {
          window?.localStorage.setItem('noDataModal', catchmentId || 'no catchment')
          window?.dispatchEvent(new CustomEvent('noDataModal'))
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        saveData = {
          loading: false,
          error: e.message,
          value: null,
        }
      }
      finally {
        if (previous) { setCanBeReset(false) }
        if (saveData?.value) { dispatch(saveTileData('big_ben', tileId, saveData)) }
        if (modelParams || previous) { setButtonLoading(false) }
      }
    }
  }, [accepted, token, shopArea])

  const mapModelParamsData = (data: ModelParams): Record<DataType, number> => {
    let mappedData = {}

    for (const key in data) {
      mappedData = ({
        ...mappedData,
        [key]: data[key].number,
      })
    }
    return mappedData
  }

  const getManagePotentialModelData = (
    data: ModelParams,
    labels: SalesPotentialLabels,
  ): ManagePotentialModelData[] =>
    data && Object.entries(data)?.map((entry) => ({
      key: entry[0] as DataType,
      label: labels?.find((label) => label?.key === entry[0])?.value || 'Value',
      value: labels?.find((label) => label?.key === entry[0])?.unit === '%' ? entry[1]?.number * 100 : entry[1]?.number,
      unit: labels?.find((label) => label?.key === entry[0])?.unit || '',
      step: labels?.find((label) => label?.key === entry[0])?.step || 10,
      min: labels?.find((label) => label?.key === entry[0])?.min,
      max: labels?.find((label) => label?.key === entry[0])?.max,
    }))

  const manipulateModelParams = (key: DataType, sign: boolean | number, step?: number,
    min?: number, max?: number): number => {
    if (!max && !min) {
      if (sign === false && modelParams[key].number - (step || 0) >= 0) { return modelParams[key].number - (step || 0) }
      if (sign === false && modelParams[key].number - (step || 0) < 0) { return 0 }
      if (typeof sign === 'number') { return sign }
      return modelParams[key].number + (step || 0)
    }

    const paramsValue = parseFloat(modelParams[key].number.toFixed(2))
    if (sign === false && paramsValue - (step || 0) >= 0 && ((min && paramsValue - (step || 0) >= min) || !min)) {
      return paramsValue - (step || 0) }
    if (sign === false && paramsValue - (step || 0) < 0) { return 0 }
    if (typeof sign === 'number') { return sign }
    if (sign === true && ((max && paramsValue + (step || 0) <= max) || (!max))) { return paramsValue + (step || 0) }
    return paramsValue
  }

  const onModelParamsChange = (key: DataType, sign: boolean | number, step?: number, min?: number, max?:number) => {
    setCanBeReset(true)
    const related = relatedValues[key]?.item

    if (related) {
      setModelParams({
        ...modelParams,
        [key]: {
          ...modelParams[key],
          number: manipulateModelParams(key, sign, step),
        },
        [related]: {
          ...modelParams[related],
          number: manipulateModelParams(related, !sign, step),
        },
      })
    } else {
      setModelParams({
        ...modelParams,
        [key]: {
          ...modelParams[key],
          number: manipulateModelParams(key, sign, step, min, max),
        },
      })
    }
  }

  const onModelParamsSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    fetchData(modelParams)
    setCanBeReset(false)
    setButtonLoading(true)
  }

  const handleSave = () => {
    dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', 'big_ben', tileId, {
      id: `${tileId}-5-walk`,
      value: 5,
      type: 'walk',
    }))
  }

  const handleDeleteTile = () => {
    dispatch(deleteTileAction(token, 'big_ben', tileId, comparedAnalyseId || analyseId || ''))
  }

  const onReset = () => {
    fetchData(undefined, true)
    setButtonLoading(true)
  }

  // hooks
  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue(token))
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData.user?.getIdToken(true)?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (fetchTileRange()) {
      setAccepted(true)
    }
  }, [fetchTileRange()])

  useEffect(() => {
    if (!data?.value) { fetchData() }
    else {
      setModelParams(data?.value?.scoringModel?.data)
      setShopArea(data?.value?.scoringModel?.shopArea)
    }
  }, [fetchData, data?.value])

  return (
    <>
      {!accepted
        ? (
          <Wrapper background={!accepted ? 'dark' : 'light'}>
            <Title>
              {t('placeme.big_ben_potential_tile.header')}
            </Title>

            <TypeRangeWrapper>
              <p>{t('placeme.big_ben_potential.input_info')}</p>
              <div>
                <InputWrapper>
                  <span>{t('placeme.type_range_selector.input_label.area')}</span>
                  <NumberInput
                    onChange={(e: SetStateAction<string>) => setArea(e)}
                    step={1}
                    unit='m2'
                    value={area}
                  />
                </InputWrapper>
              </div>
            </TypeRangeWrapper>

            <TileFooter
              disabled={!area}
              isUnlimited
              label={t('generic.apply')}
              onAccept={handleSave}
              onCancel={handleDeleteTile}
              value='1'
            />
          </Wrapper>
        )
        : (!data?.value || data?.loading
          ? (<Loader />)
          : (
            <Wrapper background='light'>
              <Title>
                {t('placeme.big_ben.potential.title')}
              </Title>
              <SettingsBox
                sectionTile='big_ben'
                setAccepted={setAccepted}
                tile={tileId}
                typeRanges={{
                  area: data?.value?.scoringModel?.shopArea?.toString(),
                }}
              />

              <Estimation>
                <p>
                  {t('placeme.big_ben.potential')}
                  {' '}
                  <strong>{data?.value?.scoringModel?.localizationPotential}</strong>
                </p>
                <span>{t('placeme.big_ben.potential.label')}</span>
              </Estimation>

              <ChartTitle>{t('placeme.sales_potential_tile.radar.title')}</ChartTitle>
              <RadarChart
                data={getRadarChartData(data?.value?.scoringModel?.data)}
                height={450}
                labels={getRadarChartLabels(data?.value?.scoringModel?.data, labels)}
              />
              <StyledManagePotentialModelParams
                boxTitle='placeme.big_ben.potential'
                boxValue={data?.value?.scoringModel?.localizationPotential}
                buttonLoading={buttonLoading}
                canBeReset={canBeReset}
                data={getManagePotentialModelData(modelParams, labels)}
                onSubmit={onModelParamsSubmit}
                onValueChange={onModelParamsChange}
                reset={onReset}
              />
            </Wrapper>
          ))}
    </>
  )
}
