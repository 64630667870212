import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { firebaseService } from '@dataplace.ai/services'
import firebase from 'firebase/app'
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../redux/store'

// Define a type for the slice state
interface ISignInState {
  email: Lowercase<string>,
  password: string,
}

export interface IFormUpdateData {
  field: 'email' | 'password',
  value: string,
}

// async functions
export const signIn: AsyncThunk<firebase.auth.UserCredential, void, Record<string, string>> = createAsyncThunk(
  'auth/signIn',
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    // const { dispatch } = thunkApi

    return firebaseService.auth().signInWithEmailAndPassword(
      state.signIn.email?.toLowerCase(), state.signIn.password,
    )
      .then(async userCredential => userCredential)
      .catch(error =>
        // https://firebase.google.com/docs/auth/admin/errors
        thunkApi.rejectWithValue(error?.code))
  },
)

// Define the initial state using that type
const initialState: ISignInState = {
  email: '',
  password: '',
}

export const signInSlice = createSlice({
  name: 'signIn',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSignInFormData: (state, action: PayloadAction<IFormUpdateData>) => ({
      ...state,
      [action.payload.field]: action.payload.value,
    }),
  },
  extraReducers: (_builder) => {
    // builder.addCase(signIn.fulfilled, (_state, _action) => {
    //
    // })
  },
})

export const { setSignInFormData } = signInSlice.actions

export default signInSlice.reducer
