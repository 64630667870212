import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { firebaseEastRegion, firebaseService } from '@dataplace.ai/services'
import firebase from 'firebase/app'
// eslint-disable-next-line import/no-cycle
import { getAxios } from '@dataplace.ai/functions/utils'
import { RootState } from '../../redux/store'
import { AppThunk } from '../../../../placeme/src/redux/store'
import { config } from '../../../../placeme/src/config'
import { ENDPOINTS } from '../../../../account/src/constants/endpoints'

// Define a type for the slice state
interface ISignUpState {
  nameAndSurname: string,
  email: Lowercase<string>,
  password: string,
  marketingConsent: boolean,
  verificationCode: string,
}

export interface IFormUpdateData {
  field: 'email' | 'password' | 'nameAndSurname' | 'verificationCode' | 'marketingConsent',
  value: string | boolean,
}

// async functions
export const signUp: AsyncThunk<firebase.auth.UserCredential, string, Record<string, string>> = createAsyncThunk(
  'auth/signUp',
  async (lang:string, thunkApi) => {
    const state = thunkApi.getState() as RootState
    // const { dispatch } = thunkApi

    return firebaseService.auth().createUserWithEmailAndPassword(
      state.signUp.email?.toLowerCase(), state.signUp.password,
    )
      // myślę, że to co się stało powyżej. jeden dodatek - używamy funkcji toLowercase, by uniknąć tego co się działo
      // przy cognito - czyli ludzi wpisywali maile np. z capsa i potem się dziwili czemu im nie działa
      // jak wpisują inaczej przy logowaniu
      .then(async userCredential => {
        // by ujednolicić dane, updatujemy imię i nazwisko usera. dodatkowo używamy custom claims - customowych pól
        // - żeby zapisać pozostałe dane
        // żeby to wszystko zrobić, musimy najpierw "wygenerować" funkcję na nasze potrzeby (linijka 42)
        // , a potem ją wywołać
        const updateUserData = firebase
          .app()
          .functions(firebaseEastRegion)
          .httpsCallable('updateUserData')

        await updateUserData({
          newUserData: {
            displayName: state.signUp.nameAndSurname,
          },
          customClaims: {
            marketingConsent: state.signUp.marketingConsent,
            language: lang,
          },
        })
          .then((response: firebase.functions.HttpsCallableResult) => response) // response = {}
          .catch(error => thunkApi.rejectWithValue(error))

        return userCredential
      })
      .catch(error =>
        // https://firebase.google.com/docs/auth/admin/errors
        thunkApi.rejectWithValue(error?.code || 'auth.error.creating_user'))
  },
)

export const verifyCode:
AsyncThunk<firebase.functions.HttpsCallableResult, { token: string; language: string; invitation: boolean; },
Record<string, string>> = createAsyncThunk(
  'auth/verifyCode',
  async ({
    token, language, invitation,
  }, thunkApi) => {
    const state = thunkApi.getState() as RootState

    const verifyCode = firebase
      .app()
      .functions(firebaseEastRegion)
      .httpsCallable('verifyCode')

    return verifyCode({
      code: state.signUp.verificationCode,
      token,
      language,
      invitation,
    })
      .then((response: firebase.functions.HttpsCallableResult) => response) // response = {}
      .catch(error => thunkApi.rejectWithValue(error.message))
  },
)

export const sendNewVerifyingCode:
AsyncThunk<firebase.functions.HttpsCallableResult, void, Record<string, string>> = createAsyncThunk(
  'auth/verifyCode',
  async (_, thunkApi) => {
    const generateNewCode = firebase
      .app()
      .functions(firebaseEastRegion)
      .httpsCallable('generateNewCode')

    return generateNewCode()
      .then((response: firebase.functions.HttpsCallableResult) => response) // response = {}
      .catch(error => thunkApi.rejectWithValue(error.message))
  },
)

export const getInvitationDetails = (invitationId: string): AppThunk => async (dispatch): Promise<void> => {
  let data

  try {
    const response = await getAxios(config.API_URL).get(`${ENDPOINTS.INVITATION}/${invitationId}`)
    data = {
      email: response.data.email,
    }
  } catch (e) {
    data = {
      email: null,
    }
  } finally {
    dispatch(saveEmail(data?.email))
  }
}

// Define the initial state using that type
const initialState: ISignUpState = {
  nameAndSurname: '',
  email: '',
  password: '',
  marketingConsent: false,
  verificationCode: '',
}

export const signUpSlice = createSlice({
  name: 'signUp',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSignUpFormData: (state, action: PayloadAction<IFormUpdateData>) => ({
      ...state,
      [action.payload.field]: action.payload.value,
    }),
    saveEmail: (state, action) => {
      state.email = action.payload
    },
  },
  extraReducers: (_builder) => {
    // builder.addCase(signUp.fulfilled, (_state, _action) => {
    //
    // })
  },
})

export const {
  setSignUpFormData, saveEmail,
} = signUpSlice.actions

export default signUpSlice.reducer
