/* eslint-disable no-console */
import axios, { AxiosInstance } from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import camelcaseKeys from 'camelcase-keys'
import { localStorageWorkspaceId } from '.'
import { handleTokenExpire } from '../session'
import { createFlashMessage } from './createFlashMessage'

export const getAxios = (baseUrl?: string, header?: string, formData?: boolean,
  catchmentId?: string) : AxiosInstance => {
  const testMode = process.env.NX_ENV !== 'PROD'
  const axiosInstance = applyCaseMiddleware(axios.create(
    {
      baseURL: baseUrl,
    },
  ))
  axiosInstance.defaults.headers.common.Authorization = header || ''
  const workspaceId = localStorage?.getItem(localStorageWorkspaceId)
  if (formData && workspaceId) {
    axiosInstance.defaults.headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: header || '',
      'Workspace-Id': workspaceId,
    }
  }

  if (workspaceId) {
    axiosInstance.defaults.headers = {
      ...axiosInstance.defaults.headers,
      'Workspace-Id': workspaceId,
    }
  }

  let body: {
    // eslint-disable-next-line camelcase
    catchment_id?: string
    catchmentId?: string
    data?: Record<string, {
      influence: number;
      number: number;
    }>;
    transportMode?: string,
    additionalParameters?: {
      transportMode?: string,
    }
  }

  let url : string | undefined

  // usable for debugging
  axiosInstance.interceptors.request.use(
    (value) => {
      body = value.data
      // eslint-disable-next-line prefer-destructuring
      url = value.url
      if (testMode) {
        console.log('\n')
        console.log('getAxios request ↓')
        console.dir(value)
        console.log('getAxios caller ↓ ')
        console.trace()
        console.log('\n')
      }
      return value
    },
  )

  axiosInstance.interceptors.response.use(
    (response) => camelcaseKeys(response, {
      deep: true,
    }),
    (error) => {
      if (testMode) {
        console.log('getAxios error ↓')
        console.dir(error)
      }

      // transport error = status.error.eufrat.travel_distance.no_data
      if (url?.includes('data') && error?.response?.data?.message === 'status.error.eufrat.travel_distance.no_data') {
        window?.localStorage.setItem('transportTypeError', body?.transportMode
        || body?.additionalParameters?.transportMode
        || '')
        window?.dispatchEvent(new CustomEvent('transportTypeError'))
      }

      // 504 - timeout
      if (error.response.status === 504 && (url?.includes('data') || url?.includes('bigben'))) {
        if (!((url?.includes('potential_model') || url?.includes('bigben/scoring_model')) && body.data)) { window?.localStorage.setItem('timeoutModal', body.catchment_id || body.catchmentId || 'no catchment') }
        else { window?.localStorage.setItem('timeoutModal', 'no catchment') }
        window?.dispatchEvent(new CustomEvent('timeoutModal'))
      }

      // 500 - server error
      if (error.response.status === 500 && (url?.includes('data') || url?.includes('bigben'))) {
        if (!((url?.includes('potential_model') || url?.includes('bigben/scoring_model')) && body.data)) {
          window?.localStorage.setItem('serverErrorModal', body.catchment_id || body.catchmentId || catchmentId || 'no catchment')
        }
        else {
          window?.localStorage.setItem('serverErrorModal', 'no catchment')
        }
        window?.dispatchEvent(new CustomEvent('serverErrorModal'))
      }

      if (error?.response?.data?.message === 'status.error.generic.invalid_token_provided' || error?.response?.data?.status === 'status.error.generic.invalid_token_provided') {
        handleTokenExpire()
      }

      createFlashMessage({
        message: error?.response?.data?.status || error?.response?.data?.message || 'status.info.generic.something_went_wrong',
      })

      Promise.reject(error?.response?.data || 'status.info.generic.something_went_wrong')
    },
  )

  return axiosInstance
}

