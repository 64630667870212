import { placemePlans } from '@dataplace.ai/constants'
import { PopupWithOverlay, CloseIcon, Loader } from '@dataplace.ai/ui-components/atoms'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { RootState } from 'apps/placeme/src/redux/store'
import { tilesData } from 'apps/placeme/src/features/Analyse/utils/tilesData'
import React, { useCallback, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getCookieWithoutJSON } from '@dataplace.ai/functions/utils'
import { ICombinedSectionTile } from '../../../slice/@types/ISectionTile'
import { setDemoModalId } from '../../../slice/analysisSlice'
import { DropdownTile } from '../DropdownTile'

interface IWrapper {
  isOpen?: boolean
}

const Wrapper = styled.div<IWrapper>(
  ({ isOpen }) =>
    css`
      display: flex;
      flex-direction: column;
      margin: 1rem 0 2rem 0;
      ${!isOpen
      && css`
        margin-top: 0;
      `}
    `,
)

const DemoWrapper = styled.div(({
  theme: {
    palette, typography, corners,
  },
}) => css`
  width: 45vw;
  height: 78vh;
  padding: 2rem;
  overflow: hidden;
  background-color: ${palette.light.white};
  border-radius: ${corners.default.borderRadius};
  color: ${palette.black};

  .header {
    font-size: ${typography.big.pt_28_semibold.fontSize};
    font-weight: ${typography.big.pt_28_semibold.fontWeight};
    line-height: ${typography.big.pt_28_semibold.lineHeight};
  }

  .header_wrapper {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
  }

  .description {
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
    margin-left: 1rem;
    > li:not(:last-child) {
      margin-bottom: .5rem;
    }
  }

  .demo_heading {
    font-size: ${typography.main.pt_15_semibold.fontSize};
    font-weight: ${typography.main.pt_15_semibold.fontWeight};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
  }

  .screenshot_wrapper {
    padding: 1rem;
    margin-top: 1rem;
    width: 80%;
  }

  .screenshot_wrapper > img {
    width: 100%;
  }

  .content {
    padding-bottom: 2rem;
  }
`)

const StyledPerfectScrollbar = styled(PerfectScrollbar)`
  margin-left: .5rem;
`

interface ISidebarList {
  isOpen?: boolean
  data: ICombinedSectionTile[]
  handleCategoryOpen: (categoryId: string) => void
  showDisabledByPricingPlan: boolean | undefined
  handleCompareLocation: (shouldBeCompare: boolean)=> void
}

export const SidebarList = ({
  isOpen,
  data,
  handleCategoryOpen,
  showDisabledByPricingPlan,
  handleCompareLocation,
}: ISidebarList): JSX.Element => {
  // constants
  const {
    values, canBeSave, demoModalId,
  } = useSelector(
    (state: RootState) => state.analysis,
  )
  const { currentSubscriptionData } = useSelector(
    (state: RootState) => state.location,
  )
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  // state ///////////////////////////////////////////
  const [demoModalImageLoaded, setDemoModalImageLoaded] = useState(false)

  // functions
  const handleCategoryDisabled = (sectionId: string) => {
    let isDisabled = false
    if (!canBeSave) {
      if (values.length) {
        if (values.find(val => val.id === sectionId)) {
          isDisabled = false
        } else {
          isDisabled = true
        }
      } else {
        isDisabled = true
      }
    }
    return isDisabled
  }

  const handleDemoModalClose = () => {
    dispatch(setDemoModalId(''))
    setDemoModalImageLoaded(false)
  }

  const DemoModal = useCallback(() => {
    if (![placemePlans.trial, 'white']
      .find(item => item === currentSubscriptionData?.value?.planExact)) { return null }
    const tile = tilesData?.map(category => category.tiles).flat()
      ?.find(tile => tile.id === demoModalId)
    if (!tile) { return null }
    return (
      <PopupWithOverlay
        onClose={handleDemoModalClose}
        open={!!demoModalId}
      >
        <DemoWrapper>
          <div className='header_wrapper'>
            <span className='header'>
              {t(`${tile.label}`)}
            </span>
            <CloseIcon onClick={handleDemoModalClose} />
          </div>
          <StyledPerfectScrollbar>
            <div className='content'>
              <ul className='description'>
                {tile.helpers.map(helper => <li key={helper}>{t(helper)}</li>)}
              </ul>
              <br />
              <span className='demo_heading'>{t('placeme.analysis.demo_modal.example_location_data')}</span>

              <div className='screenshot_wrapper'>
                {!demoModalImageLoaded
                  ? <Loader />
                  : null}
                <img
                  alt='demo_image'
                  onLoad={() => setDemoModalImageLoaded(true)}
                  src={tile?.demoImages ? tile.demoImages[getCookieWithoutJSON('language') ?? 'en'] : undefined}
                  style={{
                    visibility: demoModalImageLoaded ? 'visible' : 'hidden',
                  }}
                />
              </div>
            </div>
          </StyledPerfectScrollbar>
        </DemoWrapper>
      </PopupWithOverlay>
    )
  }, [demoModalId, demoModalImageLoaded])

  return (
    <Wrapper isOpen={isOpen}>
      {([placemePlans.trial, 'white'].find(item => item === currentSubscriptionData?.value?.planExact)) && (
        <DemoModal />
      )}
      {data.map((sectionTile) => (
        <DropdownTile
          key={sectionTile.id}
          disabled={handleCategoryDisabled(sectionTile.id)}
          full={isOpen}
          handleCategoryOpen={handleCategoryOpen}
          handleCompareLocation={handleCompareLocation}
          isCategoryOpen={sectionTile.isCategoryOpen}
          sectionTile={sectionTile}
          showDisabledByPricingPlan={showDisabledByPricingPlan}
          subscriptionType={currentSubscriptionData?.value?.planExact || ''}
        />
      ))}
    </Wrapper>
  )
}

SidebarList.defaultProps = {
  isOpen: true,
}
