import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features'
import { StatusInfoLabel } from '@dataplace.ai/ui-components/molecules'
import { IPricingPlan } from '@dataplace.ai/constants'
import { RootState } from '../../../../../redux/store'
import { SectionTile } from '../../molecules'
import { ReactComponent as SpinnerIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/spinner.svg'
import { AnalyseTemplates } from '..'
import { catchmentAndDataAction, fetchUsersTemplatesAction, saveTemplateState, saveTemplateTilesLoading } from '../../../slice/analysisSlice'
import { IAnalysisState } from '../../../slice/@types/IAnalysisState'
import { ITemplateState } from '../../../slice/@types/ITemplateState'
import { checkIfDisabledByPricingPlan } from '../../../functions/checkIfDisabledByPricingPlan'
import { ITemplatesData, templatesData } from '../AnalyseTemplates/templatesData'
// comment for clients version
// import { DataSetUpdateInfoPageLink } from '../../atoms'

const Wrapper = styled.div<{isInWhiteOrTrial?: boolean}>(({
  theme, isInWhiteOrTrial,
}) => {
  const { typography } = theme
  return css`
    height: ${isInWhiteOrTrial && 'calc(100% - 128px)'};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > :first-child > div {
      border-top: none;
    }
  `
})

const NoItems = React.memo(styled.div<{isInWhiteOrTrial?: boolean}>(({
  theme, isInWhiteOrTrial,
}) => {
  const { typography } = theme
  return css`
    display: flex;
    align-items: center;
    justify-content: ${isInWhiteOrTrial ? 'center' : 'flex-start'};
    margin: ${!isInWhiteOrTrial && '3rem 0'};
    padding-left: ${!isInWhiteOrTrial && '3rem'};
    width: ${isInWhiteOrTrial && '100%'};
    height: ${isInWhiteOrTrial && '90%'};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > img {
      width: ${isInWhiteOrTrial ? '40%' : '239px'};
    }

    > div {
      width:  ${isInWhiteOrTrial ? '20%' : '50%'};
      margin-left:  ${isInWhiteOrTrial ? '2rem' : '4rem'};

      > span:nth-of-type(1n) {
        margin-bottom: 0.5rem;
        display: block;
        font-size: ${typography.main.pt_15_regular.fontSize};
        font-weight: ${typography.main.pt_15_regular.fontWeight};
        line-height: ${typography.main.pt_15_regular.lineHeight};
      }
  
      > span:nth-of-type(2n) {
        display: block;
        font-size: ${typography.big.pt_18_semibold.fontSize};
        font-weight: ${typography.big.pt_18_semibold.fontWeight};
        line-height: ${typography.big.pt_18_semibold.lineHeight};
      }
    }
  `
}))

const SpinnerWrapper = styled.div`
  margin: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Spinner = styled(SpinnerIcon)`
  width: 1.8rem;
  height: 1.8rem;
  opacity: .5;
  margin-right:.5rem;

  animation: rotation 0.8s infinite linear;
  @keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
  }
`

export const AnalysePageBody = ({ userId }:{userId: string}): JSX.Element => {
  // constants
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  // we need ref to get current state in event listener
  const authContext = useContext(AuthContext)
  const [token, _setToken] = useState('')
  const tokenRef = React.useRef(token)
  const setToken = (newTokenRef:string) => {
    tokenRef.current = newTokenRef
    _setToken(newTokenRef)
  }
  const {
    values, synchronousTilesLoading, templateState, tiles, templateTilesLoading, plans,
    usersTemplates, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const {
    currentWorkspaceData, currentSubscriptionData,
  } = useSelector((state: RootState) => state.location)
  // tiles for templates
  const [defaultTemplatesData, setDefaultTemplatesData] = useState<ITemplatesData[]>()
  const [usersTemplatesData, setUsersTemplatesData] = useState<ITemplatesData[]>()

  // fetch data for tiles
  const [tilesNextToLoad, setTilesNextToLoad] = useState<IAnalysisState['templateState']>()
  const [isTemplateLoadingStatusBar, setIsTemplateLoadingStatusBar] = useState(false)
  const [tilesNumberWithLoadedData, setTilesNumberWithLoadedData] = useState(0)

  const isInWhiteOrTrial = currentSubscriptionData?.value?.planExact && ['white', 'trial'].includes(currentSubscriptionData?.value?.planExact)

  // functions
  const getCategoryId = (tileId: string) =>
    tiles?.find(cat => !!cat?.tiles?.find(tile => tile?.id === tileId))?.id

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (token) dispatch(fetchUsersTemplatesAction(token))
  }, [token])

  // check is tile in plan -> add isInPlan to tile object and plan
  useEffect(() => {
    if (plans
      && tiles
      && typeof currentSubscriptionData?.value?.planExact === 'string'
      && !isInWhiteOrTrial
    ) {
      // default
      setDefaultTemplatesData(templatesData?.map(template => ({
        ...template,
        tiles: template?.tiles?.map(tile => ({
          ...tile,
          plan: tiles?.find(cat => cat?.id === getCategoryId(tile?.id.split('-')?.[0]))?.tiles?.find(item =>
            item?.id === tile?.id.split('-')?.[0])?.plan as IPricingPlan,
          isInPlan: !checkIfDisabledByPricingPlan(
            plans,
            tiles?.find(cat => cat?.id === getCategoryId(tile?.id.split('-')?.[0]))?.tiles?.find(item =>
              item?.id === tile?.id.split('-')?.[0])?.plan as IPricingPlan,
            currentSubscriptionData?.value?.planExact as string,
          ),
        })),
      })))
      // users
      if (usersTemplates) {
        setUsersTemplatesData(usersTemplates?.map(template => ({
          ...template,
          tiles: template?.tiles?.map(tile => ({
            ...tile,
            plan: tiles?.find(cat => cat?.id === getCategoryId(tile?.id.split('-')?.[0]))?.tiles?.find(item =>
              item?.id === tile?.id.split('-')?.[0])?.plan as IPricingPlan,
            isInPlan: !checkIfDisabledByPricingPlan(
              plans,
              tiles?.find(cat => cat?.id === getCategoryId(tile?.id.split('-')?.[0]))?.tiles?.find(item =>
                item?.id === tile?.id.split('-')?.[0])?.plan as IPricingPlan,
              currentSubscriptionData?.value?.planExact as string,
            ),
          })),
        }))) }
    }
  }, [plans, currentSubscriptionData, tiles, usersTemplates])

  useEffect(() => {
    // setting templateTilesLoading
    if (templateState?.length) {
      if (templateState?.filter(tile => tile?.loading)?.length) {
        if (!isTemplateLoadingStatusBar) setIsTemplateLoadingStatusBar(true)
        dispatch(saveTemplateTilesLoading(true))
      } else if ((!tilesNextToLoad?.length)) dispatch(saveTemplateTilesLoading(false))
    }
    // templates - synchronous tiles fetching
    // there are some tiles more to load and user chose to use template
    if (tilesNextToLoad?.length && templateState?.length) {
      const numberOfLoadingTiles = templateState?.filter(tile => tile?.loading)?.length
      // there are less then 4 tiles loading currently (except those with additional settings)
      if (numberOfLoadingTiles < 4) {
        const difference = 4 - numberOfLoadingTiles
        const newTemplateState: ITemplateState[] = []
        // takes difference between 4 and currently loading tiles from tilesNextToLoad
        tilesNextToLoad?.slice(0, difference)?.forEach(tile => {
          if (!tile?.error?.length && !tile?.loading) {
            // makes array with new templateState (updates loading)
            templateState?.forEach(item => {
              if (item?.tile === tile?.tile) { newTemplateState.push({
                ...item,
                loading: true,
              })
              } else {
                newTemplateState.push(item)
              }
            })
            // and fires fetching data fore them
            dispatch(catchmentAndDataAction(
              token,
              {
                ...tile?.chosenRange,
                id: `${tile?.chosenRange?.type}-${tile?.chosenRange?.value}`,
              },
              getCategoryId(tile.tile.split('-')?.[0]) || '',
              tile.tile,
              currentSubscriptionData?.value?.subscriptionId || '',
            ))
          }
        })
        dispatch(saveTemplateState(newTemplateState))
        // save tiles that data still needs to be fetched
        setTilesNextToLoad(tilesNextToLoad?.slice(difference, tilesNextToLoad?.length))
      }
    }
  }, [templateState, tilesNextToLoad])

  useEffect(() => {
    if (values?.length && templateState?.length && isTemplateLoadingStatusBar) {
      // loaded tiles number
      let loadedTilesNumber = 0
      values?.forEach(cat => cat.tiles.forEach(tile => {
        if (tile?.data?.value) loadedTilesNumber += 1 }))
      setTilesNumberWithLoadedData(loadedTilesNumber)
    }
  }, [values])

  return (
    <Wrapper isInWhiteOrTrial={isInWhiteOrTrial as boolean}>
      {/* comment for clients version */}
      {/* <AskCircle /> */}
      {!!values.length
      && userId
      && values.map((value) => (
        <SectionTile
          key={value.id}
          sectionTile={value}
          userId={userId}
        />
      ))}
      {/* comment for clients version */}
      {/* {!!values && <DataSetUpdateInfoPageLink />} */}
      {!values.length && !synchronousTilesLoading && !templateTilesLoading && (
        <>
          <NoItems isInWhiteOrTrial={isInWhiteOrTrial}>
            <img
              alt='noItems'
              src='assets/icons/noItemsTemplates.svg'
              // src='assets/images/noItems2.svg'
            />
            <div>
              <span>{t('placeme.analyse_page.no_items')}</span>
              <span>{t('placeme.analyse_page.no_items_description')}</span>
              {!isInWhiteOrTrial
              && (
                <>
                  <span>{t('placeme.analyse_page.no_items.or')}</span>
                  <span>{t('placeme.analyse_page.no_items_description.template')}</span>
                </>
              )}
            </div>
          </NoItems>
          {currentWorkspaceData
          && defaultTemplatesData
          && usersTemplatesData
          && !isInWhiteOrTrial
          && (
            <AnalyseTemplates
              comparedLocation={comparedLocation}
              defaultTemplatesData={defaultTemplatesData}
              setTilesNextToLoad={setTilesNextToLoad}
              token={token}
              usersTemplatesData={usersTemplatesData}
              workspaceName={currentWorkspaceData?.value?.name || 'My Workspace'}
            />
          )}
        </>
      )}
      {(synchronousTilesLoading || templateTilesLoading)
        && (
          <SpinnerWrapper>
            <Spinner />
            <span>{t('generic.tiles_loading')}</span>
          </SpinnerWrapper>
        ) }
      {isTemplateLoadingStatusBar
         && (
           <StatusInfoLabel
             error={!!templateState?.filter(tile => tile?.error?.length)?.length}
             errorParagraph={t('placeme.templates.status_bar.error_para')}
             errorSpan={`${templateState?.filter(tile => tile?.error?.length)?.map(tile => `${t(`placeme.tile.${tile?.tile?.split('-')?.[0]}`)}, `)}`}
             errorSpan2={t('placeme.templates.status_bar.error_span2')}
             handleClose={() => {
               setIsTemplateLoadingStatusBar(false)
             }}
             loading={templateTilesLoading}
             loadingParagraph={t('placeme.templates.status_bar.loading_para', {
               firstNumber: tilesNumberWithLoadedData,
               secondNumber:templateState?.length,
             })}
             loadingSpan={templateState?.filter(tile => tile?.hasAdditionalSettings)?.length ? t('placeme.templates.status_bar.loading_span.additional_settings') : ''}
             successParagraph={templateState?.filter(tile => tile?.hasAdditionalSettings)?.length ? t('placeme.templates.status_bar.success_para.additional_settings') : t('placeme.templates.status_bar.success_para')}
             successSpan={(templateState?.filter(tile => tile?.hasAdditionalSettings)?.length && tilesNumberWithLoadedData !== templateState?.length) ? t('placeme.templates.status_bar.success_span.additional_settings') : ''}
           />
         )}
    </Wrapper>
  )
}
