export const spendingTableData = {
  labels: [
    'placeme.spending_tile.table_label_1',
    'placeme.spending_tile.table_label_2',
    'placeme.spending_tile.table_label_3',
    'placeme.spending_tile.table_label_4',
  ],
  values: [
    ['placeme.spending_tile.table_category_1', '581', '4112160', '+3%'],
    ['placeme.spending_tile.table_category_2', '687', '6137976', '-5%'],
    ['placeme.spending_tile.table_category_3', '1937', '12237551', '+15%'],
    ['placeme.spending_tile.table_category_4', '581', '4112160', '-5%'],
    ['placeme.spending_tile.table_category_5', '687', '6137976', '-7%'],
    ['placeme.spending_tile.table_category_6', '687', '6137976', '-7%'],
    ['placeme.spending_tile.table_category_7', '687', '6137976', '-7%'],
    ['placeme.spending_tile.table_category_8', '687', '6137976', '-7%'],
    ['placeme.spending_tile.table_category_9', '687', '6137976', '-7%'],
    ['placeme.spending_tile.table_category_10', '687', '6137976', '-7%'],
    ['placeme.spending_tile.table_category_11', '687', '6137976', '-7%'],
    ['placeme.spending_tile.table_category_12', '687', '6137976', '-7%'],
  ],
}
