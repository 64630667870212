import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { RcSliderWrapper } from '@dataplace.ai/ui-components/atoms'
import { RangeType } from '@dataplace.ai/types'
import { IRangeSliderProps } from './@types/IRangeSlider.props'
import { ISliderMatchHandleProps } from './@types/ISliderMatchHandleProps.props'

const StyledSliderWrapper = styled(RcSliderWrapper)(() => css`
    margin-left: 2.5rem;
  `)
interface ILabel {
  disabled?: boolean
}
const Labels = styled.div<ILabel>(({
  theme, disabled,
}) => {
  const {
    typography, palette,
  } = theme
  return css`
    display: flex;
    justify-content: space-between;
    color: ${disabled ? palette.product.location.medium : 'white'};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
  `
})

const sliderHandle = (sliderProps: ISliderMatchHandleProps, unit: string) => {
  const { Handle } = Slider
  const {
    value, ...restProps
  } = sliderProps

  return (
    <div className='rc-slider-handle-main'>
      <span
        className='rc-slider-value'
        style={{
          marginLeft: '30px',
        }}
      >
        {unit === 'km' ? (value / 1000) || 0 : value}
        {unit}
      </span>
      <Handle
        value={value}
        {...restProps}
      />
    </div>
  )
}

export const RangeSlider: FC<IRangeSliderProps> = ({
  setCustomRange,
  type,
  handleRemoveLayers,
  maxRanges,
  tile,
}) => {
  const handleValueChange = (value: number, type: RangeType) => {
    handleRemoveLayers()
    setCustomRange(state => ({
      ...state,
      id: `${type}-${value}`,
      value,
      type,
    }))
  }

  const bigBen50mLineTrafficRange = tile.split('-')[0] === 'traffic_big_ben' ? 50 : 100
  const bigBen50mLineTrafficRangeMinLabel = tile.split('-')[0] === 'traffic_big_ben' ? '50 m' : '0.1 km'
  const bigBen50mLineTrafficRangeMinUnit = (value: number) => (value < 100 ? 'm' : 'km')

  return (
    <StyledSliderWrapper disabled={type === 'custom'}>
      {type === 'walk' && (
        <>
          <Slider
            defaultValue={8}
            handle={(props: ISliderMatchHandleProps) => sliderHandle(props, 'min')}
            max={maxRanges?.walk || 30}
            min={2}
            onChange={(value) => handleValueChange(value, type)}
            step={1}
          />
          <Labels>
            <span>2 min</span>
            <span>
              {maxRanges?.walk || '30'}
              {' '}
              min
            </span>
          </Labels>
        </>
      )}
      {type === 'car' && (
        <>
          <Slider
            defaultValue={8}
            handle={(props: ISliderMatchHandleProps) => sliderHandle(props, 'min')}
            max={maxRanges?.car || 30}
            min={2}
            onChange={(value) => handleValueChange(value, type)}
            step={1}
          />
          <Labels>
            <span>2 min</span>
            <span>
              {maxRanges?.car || '30'}
              {' '}
              min
            </span>
          </Labels>
        </>
      )}
      {type === 'line' && (
        <>
          <Slider
            defaultValue={1000}
            handle={(props: ISliderMatchHandleProps) =>
              sliderHandle(props, bigBen50mLineTrafficRangeMinUnit(props.value))}
            max={maxRanges?.line || 5000}
            min={bigBen50mLineTrafficRange}
            onChange={(value) => handleValueChange(value, 'line')}
            step={bigBen50mLineTrafficRange}
          />
          <Labels>
            <span>{bigBen50mLineTrafficRangeMinLabel}</span>
            <span>
              {(maxRanges ? (maxRanges?.line / 1000) : undefined) || '5'}
              {' '}
              km
            </span>
          </Labels>
        </>
      )}
      {type === 'bike' && (
        <>
          <Slider
            defaultValue={8}
            handle={(props: ISliderMatchHandleProps) => sliderHandle(props, 'min')}
            max={maxRanges?.bike || 30}
            min={2}
            onChange={(value) => handleValueChange(value, type)}
            step={1}
          />
          <Labels>
            <span>2 min</span>
            <span>
              {maxRanges?.bike || '30'}
              {' '}
              min
            </span>
          </Labels>
        </>
      )}
      {type === 'custom' && (
        <>
          <Slider
            disabled
            handle={(props: ISliderMatchHandleProps) => sliderHandle(props, 'min')}
            max={30}
            min={2}
            onChange={(value) => handleValueChange(value, type)}
            step={1}
          />
          <Labels disabled>
            <span>2 min</span>
            <span>30 min</span>
          </Labels>
        </>
      )}
    </StyledSliderWrapper>
  )
}
